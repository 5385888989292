import { FieldProps } from "forms/form.types";
import Dropdown from "../Dropdown";
import FileInput from "../FileInput";
import TextField from "../TextField";
import EmailField from "../EmailField";
import PhoneField from "../PhoneField";
import RFCField from "../RFCField";
import CURPField from "../CURPField";
import PasswordField from "../PasswordField";
import styles from "./Field.module.scss";

// name,
// label,
// value,
// type,
// required,
// placeholder,
// disabled,
// options,
// validateDomain,
// errorMessage,
// needsValidationFrom,
// needsToBeDifferentfrom,
// dynamicOptionsProp,
// optionsContext,
// size = "full-width",
// style = "MKT",
// async = false,
// isMulti = false,
// asyncSearcher = "none"

const Field: React.FC<FieldProps> = (props) => {
  const propsToSend = {
    size: "full-width",
    style: "MKT",
    async: false,
    isMulti: false,
    asyncSearcher: "none",
    ...props
  }
  // const props = {
  //   name,
  //   label,
  //   value,
  //   type,
  //   required,
  //   placeholder,
  //   disabled,
  //   options,
  //   validateDomain,
  //   errorMessage,
  //   needsValidationFrom,
  //   needsToBeDifferentfrom,
  //   dynamicOptionsProp,
  //   optionsContext,
  //   style,
  //   async,
  //   isMulti,
  //   asyncSearcher
  // };

  return (
    <div className={`${styles["field-container"]} ${styles[propsToSend.size]}`}>
      {
        {
          text: <TextField {...props} />,
          number: <TextField {...props} />,
          rfc: <RFCField {...props} />,
          curp: <CURPField {...props} />,
          tel: <PhoneField {...props} />,
          email: <EmailField {...props} />,
          password: <PasswordField {...props} />,
          dropdown: <Dropdown {...props} />,
          file: <FileInput {...props} />,
        }[propsToSend.type]
      }
    </div>
  );
};

export default Field;
