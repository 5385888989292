import  React,{useState,useEffect,useContext} from 'react';
import styles from "./Banner.module.scss";
import { faCircleExclamation,faTriangleExclamation,faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface BannerProps {
    type:"success" | "warning" | "error" | "info", 
    title?:string
    description:string
}

     const Banner: React.FC<BannerProps> = (
            {
                type = "warning",
                description,
                title=""
            }
         ) =>{

        let icon:any;
        if(type==="warning"){
            icon = faCircleExclamation
        }
        if(type==="info"){
            icon = faCircleExclamation
        }
        if(type=="error"){
            icon = faTriangleExclamation
        }
        if(type=="success"){
            icon = faCheckCircle
        }

        const renderIcon = ()=>{
            return(
                <FontAwesomeIcon onClick={()=>{return}} 
                className={styles.icon} 
                icon={icon} />
            )
        }

        const renderTitle = ()=>{
            if(title!=""){
                return(
                    <div className={styles.title}>
                        {title}
                    </div>
                )
            }
        }

        return(
            <div className={styles[type]}>
                <span className={styles.iconContainer}>
                    {renderIcon()}
                </span>
                <div className="right">
                    {renderTitle()}
                    {description}
                </div>
            </div>
        )
    }

export default Banner;