
import {postRequest} from 'config/api/api';
import env from "react-dotenv";

export type InfoCarrier = {
  IdCarrier: number;
  Name: string;
  Phone: string;
  Email?: string;
  Main?: boolean;
  Disabled?: boolean;
  CreatedAt?: string;
  IdCarriage: number;
};

const apiSaveInfoCarrier = async(infoCarriers:InfoCarrier[]) =>{
    const url = `${env.REACT_APP_BACKEND}/info-carriers`;
    const response = postRequest(url,infoCarriers)
    return response
}

export{
  apiSaveInfoCarrier
}

