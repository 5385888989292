
import {postRequest} from 'config/api/api';
import env from "react-dotenv";

export type SaveCarriageRequest = {
    IdCarriage: number;
    IdOTM?: string;
    CityOrigin?: number;
    AddressOrigin?: string;
    Rate?: number;
    CityDestiny?: number;
    AddressDestiny?: string;
    CarriageType?: number;
    Weight?: number;
    SpecialComments?: string;
    IdRoute?: number;
    CcpInfo: InfoCarriageCcp[];
  };

  export class InfoCarriageCcp {
    IdProduct?: number;
    ProductName?: string;
    UnitValue?: number;
    Weight?: number;
    Mount?:number
  }

const apiSaveDraft = async(carriageRequest:SaveCarriageRequest) =>{
    const url = `${env.REACT_APP_BACKEND}/save-carriage-draft`;
    const response = postRequest(url,carriageRequest)
    return response
}




export{
    apiSaveDraft
}

