import { Console } from "console";
import moment from "moment-timezone";
import env from "react-dotenv";

const normalizeString = (text: string) => {
    return text
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^\w\s]/gi, "")
        .toLocaleLowerCase();
};

const divideStringByFirstComma = (str: string): [string, string] => {
    const index = str.indexOf(',');
    return index === -1
      ? [str, 'error']
      : [str.substring(0, index), str.substring(index + 1)];
};

const transformDateDayMonthYear = (dateString: string | undefined) => {
    if (!dateString) {
        return "00-00-0000";
    }

    const date = moment.utc(dateString);

    if (!date.isValid()) {
        return "00-00-0000";
    }

    const localDate = date.local();
    return localDate.format("DD-MM-YYYY");
};

const getInitials = (string: string | undefined) => {
    if (string) {
        var names = string.split(" "),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    } else {
        return " ";
    }
};

const extractEmails = (str: string) => {
    if (!str) return [""];
    const emailRegex = /\S+@\S+\.\S+/g;
    const splitRegex = /[;:\s]+/g;

    let parts = str.split(splitRegex);
    let emails: string[] = [];

    for (let part of parts) {
        let match = part.match(emailRegex);
        if (match) {
            emails = emails.concat(match);
        }
    }

    if (emails.length == 0) return [str];

    return emails;
};

const timeSince = (date: string) => {
    const timeZone = env.TIMEZONE ?? "America/Monterrey";
    const now = moment().tz(timeZone, true);
    const pastDate = moment(date).tz(timeZone, true);

    if (!pastDate.isValid()) return " ";

    const seconds = Math.floor(now.diff(pastDate) / 1000);

    let interval = Math.floor(seconds / 31536000);
    if (interval > 1) {
        return `Hace ${interval} años`;
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
        return `Hace ${interval} meses`;
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
        return `Hace ${interval} días`;
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
        return `Hace ${interval} horas`;
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
        return `Hace ${interval} minutos`;
    }

    return `Hace ${interval} segundos`;
};

const capitalizeWords = (text: string | undefined | null): string => {
    if (!text) {
        return "";
    }
    return text
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" ");
};

const formatCurrency = (amount: number | string | null | undefined): string => {
    if (amount == null) {
        return "";
    }
    const parsedAmount = typeof amount === "string" ? parseFloat(amount) : amount;
    return parsedAmount.toLocaleString("es-MX", { style: "currency", currency: "MXN" }) + "MXN";
};

const capitalize = (sentence: string | null | undefined) => {
    if (sentence == null || sentence.trim()=="" || sentence=="" || sentence == undefined) {
      return "";
    }
  
    const words = sentence.split(' ');
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substring(1).toLowerCase();
    }
  
    return words.join(' ');
}

const isSameDayAsNow = (date: Date): boolean => {
    const now = new Date();
    return date.getFullYear() === now.getFullYear() &&
      date.getMonth() === now.getMonth() &&
      date.getDate() === now.getDate();
};

const proccessOTMInput = (input: string | number | null | undefined): string | number => {
    if (input === null || input === undefined || input === '') {
      return '------';
    }
    return input;
};

const isDateInTheFutureWithin24Hours = (dateString: string | undefined): boolean => {
    if (!dateString) return false;
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return false;
    if(isSameDayAsNow(date)) return true;
    const now = new Date().getTime();
    if (date.getTime() > now) {
        const difference = date.getTime() - now;
        return difference <= 24 * 60 * 60 * 1000;
    }
    return false;
};

const transformDate = (date: string) => { //Para guardar en api
    const d = new Date(date);
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const day = d.getDate();
  
    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
};

const daysDifference = (d1:Date,d2:Date)=>{
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    if(d1.getTime() > d2.getTime()){
        return  Math.round(Math.abs((d1.getTime() - d2.getTime()) / oneDay));
    }else{
        return  -1 * Math.round(Math.abs((d1.getTime() - d2.getTime()) / oneDay))
    }
    
}


export {
    normalizeString,
    transformDateDayMonthYear,
    getInitials,
    extractEmails,
    timeSince,
    capitalizeWords,
    formatCurrency,
    capitalize,
    proccessOTMInput,
    isSameDayAsNow,
    isDateInTheFutureWithin24Hours,
    transformDate,
    divideStringByFirstComma,
    daysDifference
};
