import { useEffect, useMemo, useState } from "react";
import {
  createBrowserRouter,
  Route,
  Routes,
  Navigate,
  RouterProvider
} from "react-router-dom";
import { UserProvider } from "config/userContext";
import Login from "views/Login";
import logo from './logo.svg';
import useLocalStorage from "components/hooks/useLocalStorage";
import { User } from "views/Login/Login.types";
import  OnBoarding  from "views/OnBoarding"
import MyTrips from "views/MyTrips";
import GeneralTrips from "views/GeneralTrips";
import Erasers from "views/Erasers";
import TripForm from "views/TripForm";
import Pending from "views/Pending";
import path from "path";


const router = createBrowserRouter([
  { path: "/", element: <Login /> },
  { path: "/login", element: <Login /> },
  { path: "/onboarding", element: <OnBoarding /> },
  { path: "/my-trips/:type", element: <MyTrips /> },
  { path: "/my-trips/", element: <MyTrips /> },
  { path: "/General-trips", element: <GeneralTrips /> },
  { path: "/erasers", element: <Erasers /> },
  { path: "/pending", element: <Pending /> },
  { path: "/trip/:idTrip", element: <TripForm /> },
  { path: "/trip/:idTrip/:type", element: <TripForm /> }
  ]);
  
const App = () => {
  document.title="Kango"
  
  const [userData, setUserData] = useState(
    useLocalStorage("userData", {}).storedValue
  );
  const userValue = useMemo(() => ({ userData, setUserData }), [userData]);

  return (
    <UserProvider value={userValue}>
        <RouterProvider router={router}></RouterProvider>
    </UserProvider>
  );

  /*return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );*/
}

export default App;
