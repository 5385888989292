import styles from "./TooltipInfo.module.scss";
import InfoIcon from "@mui/icons-material/Info";

interface TooltipInfoProps {
  tooltipText: string;
  size: "small" | "inherit" | "large" | "medium";
}

const TooltipInfo: React.FC<TooltipInfoProps> = ({ tooltipText, size }) => {
  return (
    <span className={styles["tooltip"]}>
      <InfoIcon fontSize={size} />
      <span className={styles["tooltiptext"]}>{tooltipText}</span>
    </span>
  );
};

export default TooltipInfo;
