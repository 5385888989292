import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { Form as FormType } from "forms/form.types";
import Button from "components/common/Button";
import RenderForm from "components/RenderForm";
import styles from "./FormWithoutContext.module.scss";
import Section from "components/common/Section";

interface FormProps {
  inputForm: FormType;
  submitTitle: string;
  style?:"MKT" | "KGO";
}

const FormWithoutContext: React.FC<FormProps> = (props) => {
   const propsToSend:FormProps = {
      style:"MKT",
      ...props
    }
//style={style} inputForm={inputForm}
  return (
      <div className={styles.container}>
        <div
          className={styles["form-container"]}
        >
          <Section style={props.style} title={propsToSend.inputForm.formTitle} titleSize="large">
            {propsToSend.inputForm.formDescription && (
              <p className={styles["form-description"]}>
                {propsToSend.inputForm.formDescription}
              </p>
            )}
            <RenderForm {...propsToSend} />
          </Section>
        </div>
      </div>
  );
};
export default FormWithoutContext;
