import { CarriageRequest, generalTrip } from "types";
import {fetchData} from 'config/api/api';
import env from "react-dotenv";

const getPendingTrips = async()=>{
    const url = `${env.REACT_APP_BACKEND}/pending-carriages`;
    const response = await fetchData(url)
    let trips: generalTrip[] = [];
    response.forEach((element:any) => {
        const cityDestiny = element.CityDestiny? element.CityDestiny.Name as string : "Not found"
        const cityDestinyState = element.CityDestiny? element.CityDestiny.State as string : "Not found"
        const carrierName = element.CupCarrier ? element.CupCarrier.Name as string : "Not found"
        trips.push({
            cityOrigin:element.CityOrigin.Name as string,
            cityDestiny: cityDestiny,
            unitType:element.UnitType.Description as string,
            eta:element.Eta as string,
            hasMultipleLocation:element.MultipleLocation as boolean,
            hasReturn:element.CloseCircuit,
            lastResponse: element.LastResponse as string | null,
            cityDestinyState:cityDestinyState,
            cityOriginState:element.CityOrigin.State as string,
            id:element.IdCarriage,
            carrier:carrierName,
            updatedAt:element.UpdatedAt,
            CarriageRequest:element.carriageRequest as CarriageRequest
        })
    });
    return trips;
}

export{
    getPendingTrips
}