import  React,{useState,useEffect,useContext} from 'react';
import styles from "./HeaderCard.module.scss";


interface HeaderCardProps{
    children?: React.ReactNode;
    title?:string,
    dark?:true

}

const HeaderCard: React.FunctionComponent<HeaderCardProps>=(props)=>{
    return(
        <>
            <div className={props.dark ? styles.headerCardDark : styles.headerCard}>
                {props.title && 
                    <div className={styles.title}>
                    {props.title}
                    </div>
                }
                <div className={styles.text}>
                    {props.children}
                </div>
            </div>
        </>
    )
}
export default HeaderCard;