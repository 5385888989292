import { product,carriageType,clientRoutes } from "types";
import {fetchData} from 'config/api/api';
import { json } from "stream/consumers";
import env from "react-dotenv";

const getProducts = async(idClientCompany:number)=>{
    const url = `${env.REACT_APP_BACKEND}/client-products/${idClientCompany}`;
    const response = await fetchData(url)
    return response as product[]
}




export{
    getProducts,
}

