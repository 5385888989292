import  React from 'react';
import {useNavigate } from 'react-router-dom';
import styles from "./BreadCrumb.module.scss";

export interface BreadCrumbProps {
    breadCrumb?:Array<{
        label:string,
        linkTo:string
    }>
}

const BreadCrumb: React.FC<BreadCrumbProps> = (
            {
                breadCrumb
            }
         ) =>{

        const navigate = useNavigate();

        const clickNavigate = (path:string)=>{
            navigate(path)
        }

        const renderLinks = ()=>{
            if (!breadCrumb  || breadCrumb.length == 0) return <></>
            const linksJsx = []
            for(let i = 0;i<breadCrumb.length-1;i++){
                linksJsx.push(
                    <div key={i} className={styles.linkContainer}>
                        <div style={{ cursor:"poiter", textDecoration: 'none',color:"#80B7CC"}} 
                        onClick={()=>{clickNavigate(breadCrumb[i].linkTo)}}>
                        {breadCrumb[i].label}</div>
                        <div>/</div>
                    </div>
                )
            }
            const lastLink = (
                <div key={breadCrumb.length-1} className={styles.linkContainer}>
                    <div style={{ cursor:"pointer", textDecoration: 'none',color:"white"}} 
                    onClick={()=>{clickNavigate(breadCrumb[breadCrumb.length-1].linkTo)}}>
                    {breadCrumb[breadCrumb.length-1].label}</div>
                </div>
            )
            linksJsx.push(lastLink)
            const links = linksJsx.map(link=>
                link
            )

            return links
        }

        const renderBreadCrumb = ()=>{
            if(breadCrumb && breadCrumb.length > 0){
                return(
                    <div className={styles.breadCrumb}>
                        <div className={styles.linkGroup}>
                            {renderLinks()}
                        </div>
                    </div>
                )
            }
        }

        return(
            <>
                {renderBreadCrumb()}
            </>
        )
    }

export default BreadCrumb;