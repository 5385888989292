import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

export function useAxiosNavigation() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const intercetpor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        switch (error?.response?.status) {
          case 401:
            navigate(`/login`,{state:{redirectTo: location}});
            break;
          default:
            console.log(`Mensje: ${error.message} response: ${error?.response}`)
            break;
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(intercetpor);
    };
  }, []);
}