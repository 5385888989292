import  React,{useState,useEffect,useContext} from 'react';
import { Link } from 'react-router-dom';
import styles from "./EmptyTrip.module.scss";


interface EmptyTripProps{
    children?: React.ReactNode;
    header?:string,
    sourceImg?:string

}

const EmptyTrip: React.FunctionComponent<EmptyTripProps>=({
    children,
    header,
    sourceImg
})=>{
    return(
        <>
            <div className={styles.noCompleted}>
                <div className={styles.containerImg}>
                    <img src={sourceImg} />
                    <div className={styles.header}>
                    {header}
                    </div>
                    <div className={styles.desc}>
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}
export default EmptyTrip;