import  React,{useState,useEffect,useContext} from 'react';
import styles from "./OnBoarding.module.scss";
import OnBoardingStepper from 'components/common/OnBoardingStepper';
import OnBoarding1 from 'components/OnBoarding1';
import OnBoarding2 from 'components/OnBoarding2';
import OnBoarding3 from 'components/OnBoarding3';
import { FieldValues } from "react-hook-form";
import OnBoardingUser from 'components/OnBoardingUser';
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import UserContext from "config/userContext";
import { useNavigate } from "react-router-dom";
import KGOButton from "components/common/KGOButton";
import PopUp from 'components/common/Popup'
import { infoUserRequest } from './OnBoarding.service';

const OnBoarding: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const { userData, setUserData } = useContext(UserContext);

    useEffect(() => {
    }, [userData, navigate]);


    const [currentStep,setCurrentStep] = useState(1)
    
    const nextStep = () =>{
        if (currentStep<totalSteps){
            setCurrentStep(currentStep+1)
        }
    }
    const backStep = () =>{
        if (currentStep>1){
            setCurrentStep(currentStep-1)
        }
    }

 const [openPopup, setopenPopup] = useState(false);

 const goHomePage = ()=>{
    setopenPopup(false)
    navigate(`/my-trips`);
 }

  const popupContent = (
		<div className={styles.popup}>
		  <div className={styles["popup-heading"]}>
            ¡Tus datos se han guardado con éxito!
		  </div>
		  <div className={styles["popup-text"]}>
            Kango te e mantendrá informado de todas las oportunidades de viajes ideales para ti, 
            a través de tu correo.
          </div>
		  <div className={styles.buttons}>
            <KGOButton color="secondary" emphasis="high" size="medium" action={goHomePage}>
                Ir a la página principal
            </KGOButton>
		  </div>
		</div>
);


    const methods = useForm({mode:"onChange"})

    const submitForm=async (data: FieldValues)=>{
        const whatsapp = data["whatsapp"];
        const email = data["email"];
        await infoUserRequest(whatsapp, email);
        setopenPopup(true)
    }


    const steps = [
    //<OnBoarding1 next={nextStep} back={backStep}></OnBoarding1>,
    //<OnBoarding2 next={nextStep} back={backStep}></OnBoarding2>,
    //<OnBoarding3 next={nextStep} back={backStep} submit={methods.handleSubmit(submitForm)}></OnBoarding3>
    <OnBoardingUser next={nextStep} back={backStep} submit={methods.handleSubmit(submitForm)}></OnBoardingUser>
    ]

    const totalSteps = steps.length;

    const StepDisplay = () => {
        return steps[currentStep -1]
    }

    return(
       <>
       <FormProvider {...methods}>
         <div>
            <div className={styles.container}>
                {StepDisplay()}
            </div>
        </div>
       </FormProvider>
       <PopUp open={openPopup} onClose={() => {}} content={popupContent} />
       </>
    )

}


export default OnBoarding;