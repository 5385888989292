import  React,{useState,useEffect,useContext} from 'react';
import styles from "./RejectedTrip.module.scss";
import { carriageDetail } from 'types';
import { Link, useNavigate } from "react-router-dom";
import {iTrip} from '../interface'
import { PropaneSharp } from '@mui/icons-material';
import KGOButton from 'components/common/KGOButton';
import PopUp from 'components/common/Popup';
import FormWithoutContext from 'components/Form/FormWithoutContext';
import { completedTripForms } from 'forms/FormsCatalogue';
import CCPForm from 'components/CCPForm';
import { FormProvider, useForm } from 'react-hook-form';
import HeaderCard from 'components/common/HeaderCard';
import Banner from 'components/common/Banner';
import { reponseId } from 'types';
import { rejectedRateForm } from 'forms/FormsCatalogue';
import { formatCurrency, transformDateDayMonthYear } from 'components/common/Utils/utils';
import { style } from '@mui/system';
import { SaveNewRatePending, savePendingCarriageNewRate } from './RejectedTrip.service';
import { AxiosResponse } from 'axios';

interface RejectedTripProps{
    trip:carriageDetail,
    savePendingCarriageRate?:(carriageRequest:SaveNewRatePending)=>Promise<AxiosResponse<any,any>>
}

const RejectedTrip: React.FC<RejectedTripProps>=({
    trip,
    savePendingCarriageRate = savePendingCarriageNewRate
})=>{
    const navigate = useNavigate();
    const clickTrips = ()=>{
        navigate(`/my-trips`);
    };

    const form = useForm();

    const [isSending, setIsSendindg] = useState<boolean>(false);

    let rejectionReasonText = trip.CarriageRequest?.carriageResponseRelation?.IdResponse == 
    reponseId.RejectedByRate ? 
    "Rechazado por tarifa" : trip.CarriageRequest?.carriageResponseRelation?.Message

    if(rejectionReasonText?.trim()==""){
        rejectionReasonText = "Transportosta no dio razón"
    }

    const [openPopup, setopenPopup] = useState(false);
    const popupContent = (
		<div className={styles.popup}>
		  <div className={styles["popup-heading"]}>
            ¡Hemos enviado la nueva tarifa al transportista!
		  </div>
		  <div className={styles["popup-text"]}>
		    Te notificaremos en cuanto recibamos respuesta sobre su disponibilidad.
		  </div>
		  <div className={styles.buttons}>
		  <KGOButton size='small'
           color='tertiary'
           emphasis='high' action={() => clickTrips()}>
		 	Ir a mis viajes
		  </KGOButton>
		  </div>
		</div>
	);



    const renderFields = ()=>{
        const fields = [
            {label:"Origen",text:trip.CarriageRequest?.cityOriginRelation?.Name},
            {label:"Destino",text:trip.CarriageRequest?.cityDestinyRelation?.Name},
            {label:"Fecha de cancelación del viaje en Kango",text:transformDateDayMonthYear(trip.CarriageRequest?.carriageResponseRelation?.UpdatedAt)},
            {label:"Tipo de Unidad",text:trip.UnitType},
            {label:"Tipo de Carga",text:trip.CarriageRequest?.carriageTypeRelation?.Description},
            {label:"Tarifa propuesta al transportista",text:formatCurrency(trip.CarriageRequest?.Rate)},
            {label:"Comentarios Adicionales",text:trip.CarriageRequest?.SpecialComments}
        ]
        return(
            <div className={styles.groupField}>
                {fields.map(field =>{
                    return (
                        <div key={field.label} className={styles.fieldContainer}>
                            <div className={styles.label}>
                                {field.label}
                            </div>
                            <div className={styles.text}>
                                {field.text}
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    const renderHeader = () =>{
        return(
            <Banner type='error'
            description={`Este viaje fue rechazado por el transportista, por lo que no es posible recuperarlo. 
            Esta pantalla sólo está habilitada para fines informativos.`}
            title='Viaje Rechazado por el Transportista'></Banner>
        )
    }

    const renderReasonField = ()=>{
        return(
            <div className={styles.reasonFieldContainer}>
                <div className={styles.rejectionBanner}>
                    <Banner type='warning'
                    title='Tarifa inicial no aceptada'
                    description='El transportista no aceptó tu oferta, si deseas continuar con este viaje realiza una nueva propuesta.'
                    ></Banner>
                </div>
                <FormWithoutContext style='KGO' 
                submitTitle='' 
                inputForm={rejectedRateForm[0]}></FormWithoutContext>
                <div className={styles.buttonContainer}>
                    <KGOButton
                    emphasis="high"
                    color='primary'
                    type="submit"
                    disabled={isSending}
                    size={"medium"}>{isSending ? "Enviando" : "Enviar nueva tarifa"}</KGOButton>
                </div>
            </div>
        )
    }

    const renderRejectionReason = ()=>{
        return(
            <>
                <div className={styles.reasonContainer}>
                    <div className={styles.title}>
                    ? Razón por la cuál el transportista canceló el viaje.
                    </div>
                    <div className={styles.label}>
                        {rejectionReasonText}
                    </div>
                </div>
            </>
        )
    }

    const renderFieldOrReasonRejection = ()=>{
        if(trip.CarriageRequest?.carriageResponseRelation?.IdResponse == reponseId.RejectedByRate){
            return renderReasonField()
        }else{
            return renderRejectionReason()
        }
    }

    const generateSaveCarriageData = (idCarriage:number,newRate:number):SaveNewRatePending=>{
        const res:SaveNewRatePending = {
            IdCarriage:idCarriage,
            Rate:newRate
        }
        return res;
    }

    const onSubmit = async (event:any) => {
        event.preventDefault();
        const result:any = await form.trigger();
        const values = form.getValues();
        if(result){
            setIsSendindg(true);
            const rate = Number(values.rate)
            const body = generateSaveCarriageData(trip.IdCarriage,rate)
            savePendingCarriageRate(body).then(res=>{
                setopenPopup(true)
            }).catch(err=>{
                alert("Ha ocurrido un problema al enviar la solicitud.");
                setIsSendindg(false);
            })
        }
    }

    return(
        <>
            <FormProvider {...form}>
                <form onSubmit={onSubmit}>
                    {renderHeader()}
                    <div className={styles["form-container"]}>
                        {renderFields()}
                        <div className={styles.ccpLabel}>Productos que enviaste:</div>
                        <CCPForm disabled={true} cartaPorte={trip.CarriageRequest?.cartaPorte}></CCPForm>
                        {renderFieldOrReasonRejection()}
                        <PopUp open={openPopup} onClose={() => {}} content={popupContent} closeButton={true} />
                    </div>
                </form>
            </FormProvider>
        </>
    )
}

export {RejectedTrip};