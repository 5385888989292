import { carriageDetail,carriageType,clientRoutes } from "types";
import {fetchData,postRequest} from 'config/api/api';
import { json } from "stream/consumers";
import env from "react-dotenv";

const getTripDetail = async(idTrip:number)=>{
    const url = `${env.REACT_APP_BACKEND}/carriage-detail/${idTrip}`;
    const response = await fetchData(url)
    return response as carriageDetail
}

const getCarriageTypesDetail = async()=>{
    const url = `${env.REACT_APP_BACKEND}/cat-carriage-types`;
    const response = await fetchData(url)
    return response as carriageType[]
}

const getClientRoutes = async()=>{
    const url = `${env.REACT_APP_BACKEND}/client-routes`;
    const response = await fetchData(url)
    return response as clientRoutes[]
}

interface savePendingArgs{
    IdCarriage:number,
    CityOrigin:number,
    AddressOrigin:string,
    CityDestiny:number,
    AddressDestiny:string,
    CarriageType:number,
    Weight:number,
    SpecialComments:string,
    IdCarrier:number | undefined,
    IdRoute:number,
    Rate:number,
    CcpInfo:Array<{
        IdProduct:number,
        ProductName:string,
        Mount:number,
        UnitValue:number,
        Weight:number
    }>
}

const savePendingCarriage = async(carriageRequest:savePendingArgs) =>{
    const url = `${env.REACT_APP_BACKEND}/save-pending-carriage`;
    const response = postRequest(url,carriageRequest)
    return response
}




export{
    getTripDetail,
    getCarriageTypesDetail,
    getClientRoutes,
    savePendingCarriage
}

