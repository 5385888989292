import { OptionsField } from "forms/form.types";
import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import styles from "./TextField.module.scss";
import TooltipInfo from "components/common/TooltipInfo";

const TextField: React.FC<OptionsField> = ({
  name,
  value,
  placeholder,
  label,
  required,
  type,
  tooltip = "N/A",
  disabled,
  pattern = /.*/,
  patternErrorMessage="Error",
  style="MKT"
}) => {
  const methods = useFormContext();
  const [currentValue, setCurrentValue] = useState(value);
  const [status, setStatus] = useState("");
  useEffect(() => {
    methods.clearErrors(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const validateText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    text !== "" ? setStatus("writing") : setStatus("not writing");
  };

  const emptyFieldWhenRequired =
    methods.formState.errors[name] &&
    methods.formState.errors[name].type === "required";

  const valueNotChanged = currentValue === value;

  const errorStyle =
    emptyFieldWhenRequired && valueNotChanged ? "input-error" : "";

  const requiredMessage = emptyFieldWhenRequired && valueNotChanged && (
    <span className={styles["error-text"]}>Este campo es requerido</span>
  );

  const patternMessage = methods.formState.errors[name] === 'pattern' && (
    <span className={styles["error-text"]}>{patternErrorMessage}</span>
  );

  if(style==='KGO'){
    return (
      <div className={styles.KGOContainer}>
        <label className={styles.KGOLabel}>{label}</label>
        <input
          {...methods.register(name, { value, required:required, pattern:pattern })}
          className={`${styles.KGOInput} ${styles[styles.KGOErrorStyle]}`}
          defaultValue={currentValue}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          onChange={(e) => {
            const value = e.target.value;
            setCurrentValue(value);
            methods.formState.errors[name] && validateText(e);
            methods.setValue(name, value);
          }}
        />
        {requiredMessage}
        {patternMessage}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <label className={styles.label}>{label} 
      {tooltip !== "N/A" &&
        <TooltipInfo tooltipText={tooltip} size="inherit" />
      }
      </label>
      <input
        {...methods.register(name, { required:required, pattern:pattern })}
        className={`${styles.input} ${styles[errorStyle]}`}
        value={currentValue}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        onChange={(e) => {
          const value = e.target.value;
          setCurrentValue(value);
          methods.formState.errors[name] && validateText(e);
          methods.setValue(name, value);
        }}
      />
      {requiredMessage}
      {patternMessage}
    </div>
  );
};

export default TextField;
