import  React,{useState,useEffect,useContext, useRef} from 'react';
import styles from "./Draft.module.scss";
import { carriageDetail } from 'types';
import { Link, useNavigate } from "react-router-dom";
import {iTrip} from '../interface'
import { generalTripForms as forms } from 'forms/FormsCatalogue';
import FormWithoutContext from "components/Form/FormWithoutContext";
import CCPForm from 'components/CCPForm';
import KGOButton from 'components/common/KGOButton';
import PopUp from 'components/common/Popup'
import { useLocation } from 'react-router-dom';
import { unstable_usePrompt } from 'react-router-dom';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { apiSaveDraft } from './Draft.service';
import { OptionsField } from 'forms/form.types';
import HeaderCard from 'components/common/HeaderCard';
import Banner from 'components/common/Banner';
import { savePendingCarriage } from 'views/TripForm/TripsForm.service';

const Draft: React.FC<iTrip>=({ trip})=>{
    const navigate = useNavigate();
    const clickGeneralTrips = ()=>{
        navigate(`/general-trips`);
    };
    const form = useForm()
    const [formState,setFormState] = useState<"saving" | "saved" | "filling">("filling")
    const formStateRef = useRef({})
    formStateRef.current = formState;

    const [openPopup, setopenPopup] = useState(false);
    const generalTripForms = Object.assign({},forms)
    const [loaded,setLoaded] = useState<boolean>(false)
    const submitButtonText = trip.LastReponse ? "Enviar" : "En espera de respuesta"
    const popupContent = (
		<div className={styles.popup}>
		  <div className={styles["popup-heading"]}>
            ¡Tu solicitud ha sido enviada al transportista!
		  </div>
		  <div className={styles["popup-text"]}>
		  Los datos se agregaron con éxito y consultamos disponibilidad 
          con el transportista. Te notificaremos en cuanto recibamos respuesta. 
          Mientras tanto, puedes consultar tu solicitud en Viajes Pendientes. 
		  </div>
		  <div className={styles.buttons}>
		  <KGOButton size='small'
           color='tertiary'
           emphasis='high' action={() => clickGeneralTrips()}>
		 	Ir a viajes generales
		  </KGOButton>
		  </div>
		</div>
	);

    const setFormValues = ()=>{
        const carriageRequest = trip.CarriageRequest;
        if(carriageRequest){
            if(carriageRequest.AddressOrigin) 
            form.setValue("originAddress",carriageRequest.AddressOrigin)
            const originField = generalTripForms[0].fields[0] as OptionsField
            const destinyField = generalTripForms[1].fields[0] as OptionsField
            
            if(carriageRequest.CityOrigin && carriageRequest.cityOriginRelation){
                form.setValue("origin",{value:carriageRequest.CityOrigin})
                generalTripForms[0].fields[0].value = carriageRequest.CityOrigin.toString()  
                originField.defaultAsyncValue = carriageRequest.cityOriginRelation.Name
            }else{
                delete originField.defaultAsyncValue;
            }
            if(carriageRequest.AddressDestiny) 
            form.setValue("destinyAddress",carriageRequest.AddressDestiny)
            if(carriageRequest.CityDestiny && carriageRequest.cityDestinyRelation){
                form.setValue("destiny",{value:carriageRequest.CityDestiny})
                generalTripForms[1].fields[0].value = carriageRequest.CityDestiny.toString()
                destinyField.defaultAsyncValue = carriageRequest.cityDestinyRelation.Name
            }else{
                delete destinyField.defaultAsyncValue;
            }
            if(carriageRequest.IdCarriageType){
                form.setValue("carriageType",carriageRequest.IdCarriageType)
            }
            if(carriageRequest.SpecialComments){
                form.setValue("SpecialComments",carriageRequest.SpecialComments)
            }
            if(carriageRequest.Rate){
                form.setValue("rate",carriageRequest.Rate)
            }
            if(carriageRequest.IdRoute){
                form.setValue("clientRoute",carriageRequest.IdRoute)
            }
        }
    }



    const formIsDirty = () => {
        const { origin, originAddress, destiny, destinyAddress, carriageType, clientRoute, SpecialComments, rate } = form.getValues();
        return origin || originAddress || destiny || destinyAddress || SpecialComments || rate;
    };

    const preventUnload = (event: BeforeUnloadEvent) => {  
        if(formIsDirty() && formStateRef.current == "filling" ){
            const message = 'Sure you want to leave?';
            event.preventDefault();
            event.returnValue = message;
            alert("leave")
            saveDraft()
        }
    };

    useEffect(()=>{
        setFormValues()
        setLoaded(true)
        window.addEventListener('beforeunload', preventUnload);
        return ()=>{
            window.removeEventListener('beforeunload', preventUnload);
            if(formIsDirty() && formStateRef.current == "filling" ){
                saveDraft()
            }
        }
    },[formState])


    const savePending = () =>{
        const values = form.getValues()
        if(!values.CcpInfo || values.CcpInfo?.length  == 0){
            alert("Por favor agrega productos al viaje.")
            setFormState("filling")
            return
        }
        const carriageRequestBody={
            IdCarriage:trip.IdCarriage,
            CityOrigin:values.origin.value,
            AddressOrigin:values.originAddress,
            CityDestiny:values.destiny.value,
            AddressDestiny:values.destinyAddress,
            CarriageType:Number(values.carriageType),
            Weight:0,
            Rate:Number(values.rate),
            SpecialComments:values.SpecialComments,
            IdCarrier:trip.InfoCarrier? trip.InfoCarrier[0].IdCarrier : -1,
            IdRoute:Number(values.clientRoute),
            CcpInfo:values.CcpInfo.map((item:any)=>{
                return {
                    IdProduct: item.IdProduct,
                    ProductName:item.ProductName,
                    Mount: Number(item.Mount),
                    Weight: Number(item.Weight),
                    UnitValue:0
                }
            })
        }
        savePendingCarriage(carriageRequestBody).then((res:any)=>{
            setopenPopup(true)
            setFormState("saved")
        }).catch((error:any)=>{
            alert("No se ha podido guardar el viaje")
        })
    }

    const saveDraft = ()=>{
        const values = form.getValues()
        const carriageRequestBody:any={
            IdCarriage:trip.IdCarriage,
            CityOrigin:values.origin?.value,
            AddressOrigin:values.originAddress,
            CityDestiny:values.destiny?.value,
            AddressDestiny:values.destinyAddress,
            CarriageType:Number(values.carriageType),
            Weight:0,
            Rate:Number(values.rate),
            SpecialComments:values.SpecialComments,
            IdCarrier:trip.InfoCarrier? trip.InfoCarrier[0].IdCarrier : -1,
            IdRoute:Number(values.clientRoute),
            CcpInfo:values.CcpInfo?.map((item:any)=>{
                return {
                    IdProduct: item.IdProduct,
                    ProductName:item.ProductName,
                    Mount: Number(item.Mount),
                    Weight: Number(item.Weight),
                    UnitValue:0
                }
            })
        }
        if(values.rate) carriageRequestBody.Rate = Number(values.rate)
        Object.keys(carriageRequestBody).forEach((key:any) => carriageRequestBody[key] === undefined ? delete carriageRequestBody[key] : {});
        apiSaveDraft(carriageRequestBody).then(response=>{
        }).catch(error=>{
            console.log("error al guardar draft",error)
        })
        
    }

    const onSubmit = async (event:any) => {
        event.preventDefault();
        const result:any = await form.trigger();
        if(result){
            setFormState("saving")
            savePending()
        }
    }

    const renderForm = ()=>{
        return (
            
                <FormProvider {...form}>
                <form
                onSubmit={onSubmit}
                className={styles["form-container"]}
                >
                    <div className={styles.title}>
                        Formulario para el transportista
                    </div>
                    <>
                     {renderForms()}   
                     <div className={styles.buttonContainer}>
                        <KGOButton type='submit'
                        emphasis="high"
                        color='primary'
                        disabled={formState=="filling" && trip.LastReponse ? false:true}
                        size={"medium"}>{formState == "filling" ? <>{submitButtonText}</> : <>Enviando</>}</KGOButton>
                     </div>
                    </>

                </form>
                </FormProvider> 
            
        )
    }

    const renderForms = ()=>{
        return(
            <>
                {loaded && 
                    <>
                    <FormWithoutContext style='KGO' 
                        submitTitle='ssdfd' 
                        inputForm={generalTripForms[0]}></FormWithoutContext>
                    <FormWithoutContext style='KGO' 
                        submitTitle='ssdfd' 
                        inputForm={generalTripForms[1]}></FormWithoutContext>
                    <FormWithoutContext style='KGO' 
                        submitTitle='ssdfd' 
                        inputForm={generalTripForms[2]}></FormWithoutContext>
                    <FormWithoutContext style='KGO' 
                        submitTitle='ssdfd' 
                        inputForm={generalTripForms[3]}></FormWithoutContext>
                    <CCPForm></CCPForm>
                    <FormWithoutContext style='KGO' 
                        submitTitle='ssdfd' 
                        inputForm={generalTripForms[4]}></FormWithoutContext>
                        <FormWithoutContext style='KGO' 
                        submitTitle='ssdfd' 
                        inputForm={generalTripForms[5]}></FormWithoutContext>
                    <PopUp open={openPopup} onClose={() => {}} content={popupContent} />
                    </>
                }
            </>
        )
    }

    const renderHeader = () =>{
        return(
            <HeaderCard title='¿Te interesa este viaje?'>
                <strong>Para que el transportista pueda conocer el destino de tu interés</strong>, por favor llena y envía el formulario. Le enviaremos la información a través de WhatsApp para conocer su disponibilidad. 
            </HeaderCard>
        )
    }

    const renderBanner = () =>{
        return(
            <Banner description="Termina de rellenar todos los campos para solicitar este viaje. recuerda que al cumplirse el ETA, el formulario se desactivará y el viaje será eliminado."
            title='Retoma y completa el formulario'
            type="info"></Banner>
        )
    }


    unstable_usePrompt({when:formIsDirty() && formState == "filling" ,message:`Al dar clic en Aceptar, el formulario pasará a la sección de Borradores, donde podrás continuar editándolo si lo deseas.`,})
    return(
        <>
            <div className={styles.headerGroup}>
                {renderHeader()}
                {renderBanner()}
            </div>
            {renderForm()}
        </>
    )
}

export{ Draft};

