import  React,{useState,useEffect,useContext} from 'react';
import styles from "./ActiveTrip.module.scss";
import { faHashtag } from '@fortawesome/free-solid-svg-icons'
import { faTruckArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faCalculator,faClock } from '@fortawesome/free-solid-svg-icons';
import { carriageDetail } from 'types';
import { transformDateDayMonthYear,capitalize,formatCurrency } from 'components/common/Utils/utils';
import { proccessOTMInput } from 'components/common/Utils/utils';

export type ActiveTripProps = {
    trip:carriageDetail
}

const ActiveTrip: React.FunctionComponent<ActiveTripProps>=(props)=>{

    const renderSchedule = () => {
        if(props.trip.Schedule){
            return(
                <div className={styles.destinyDetails}>
                    <div className={styles.icon}>
                        <FontAwesomeIcon 
                        className={styles.icon} icon={faClock} />
                    </div>
                    <div className={styles.date}>
                        Horario de llegada:
                        <br/>
                        {props.trip.Schedule}
                    </div>
                </div>
            )
        }
    }

    return(
        <div className={styles.tripContainer}>
            <input name="expandableHeader" 
            id="expandableHeader" 
            type="checkbox" 	
            className={styles["expandable-toggler"]}/>

            <label htmlFor="expandableHeader" className={styles.tripHeader}>
                <div className={styles.gcircle}></div>
                <div className={styles.title}>
                    Viaje activo de Deacero
                </div>
                <div className={styles.iconContainer}>
                     <FontAwesomeIcon  
                        className={styles.icon} icon={faChevronDown} />
                </div>
            </label>
            <div data-testid="tripinfo" className={styles.tripInfo}>
                <div className={styles.locationInfo}>
                    <div className={styles.lineContainer}></div>
                    <div className={styles.tripLocations}>
                        <div className={styles.originHeader}>
                            <div className={styles.originIconContainer}>
                                <FontAwesomeIcon 
                                className={styles.iconOrigin} icon={faLocationDot} />
                            </div>
                            <div className={styles.originTitle}>
                                Origen
                            </div>
                        </div>
                        <div className={styles.locationName}>
                            {capitalize(props.trip.CityOrigin)}, {props.trip.locationRelation?.Description}
                        </div>
                    
                        <div className={styles.destinyHeader}>
                            <div className={styles.originIconContainer}>
                                <FontAwesomeIcon 
                                className={styles.iconOrigin} icon={faTruckArrowRight} />
                            </div>
                            <div className={styles.originTitle}>
                                Destino
                            </div>
                        </div>
                        <div className={styles.destinyLocationName}>
                            {capitalize(props.trip.CityDestiny)}
                        </div>
                        <div className={styles.destinyDetails}>
                            <div className={styles.icon}>
                                <FontAwesomeIcon 
                                className={styles.icon} icon={faCalendarDays} />
                            </div>
                            <div className={styles.date}>
                                Fecha estimada de llegada:
                                <br/>
                                {transformDateDayMonthYear(props.trip.Eta)}
                            </div>
                        </div>
                        {renderSchedule()}
                    </div>
                </div>
                <div className={styles.tripDetails}>
                    <div className={styles.icon}>
                        <FontAwesomeIcon 
                        className={styles.icon} icon={faHashtag} />
                    </div>
                    <div className={styles.date}>
                        ID OTM Deacero:
                        <br/>
                        {proccessOTMInput(props.trip.IdTrip)}
                    </div>
                </div>
                <div className={styles.tripDetails}>
                    <div className={styles.icon}>
                        <FontAwesomeIcon 
                        className={styles.icon} icon={faCalculator} />
                    </div>
                    <div className={styles.date}>
                        Tarifa Deacero:
                        <br/>
                        {formatCurrency(props.trip.Rate)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ActiveTrip;