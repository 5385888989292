import  React,{useState,useEffect,useContext} from 'react';
import styles from "./SuggestedTrips.module.scss";
import { generalTrip } from 'types';
import { Link, useNavigate } from "react-router-dom";
import TripRow from 'components/TripRow';
import { getSuggestedTrips as getSuggestedTripsApi, suggestedTrips } from "components/SuggestedTrips/SuggestedTrips.service"
import SuggestedCard from "components/SuggestedCard";
import Banner from 'components/common/Banner';
import {useAxiosNavigation} from "config/api/AxiosInterceptor";
import { divideStringByFirstComma } from 'components/common/Utils/utils';
import KGOButton from 'components/common/KGOButton';
import PopUp from 'components/common/Popup'

export interface SuggestedTripsProps {
    getSuggestedTrips?:()=>Promise<suggestedTrips>,
}

const SuggestedTrips: React.FC<SuggestedTripsProps>=({
    getSuggestedTrips = getSuggestedTripsApi
})=>{
    const [routes, setRoutes] = useState<suggestedTrips>({})
    const [openPopup, setopenPopup] = useState(false);
    const navigate = useNavigate();

    const popupContent = (
		<div className={styles.popup}>
		  <div className={styles["popup-heading"]}>
            Aún no tienes viajes sugeridos
		  </div>
		  <div className={styles["popup-text"]}>
          ¡Bienvenido a Kango! Como es tu primer inicio de sesión, queremos informarte que los viajes comenzarán a aparecer en aproximadamente <strong>30 minutos</strong>. 
          Mientras tanto, puedes revisar los viajes generales. 
		  </div>
		  <div className={styles.buttons}>
          <KGOButton size='small'
           color='secondary'
           emphasis='high' action={() => {navigate("/General-trips")}}>
		 	Ir a Viajes Generales
		  </KGOButton>
		  <KGOButton size='small'
           color='tertiary'
           emphasis='medium' action={() => {setopenPopup(false)}}>
		 	Entendido
		  </KGOButton>
		  </div>
		</div>
	);

    const noSuggested = ()=>{
        if(Object.keys(routes).length > 0) return
        return(
            <div className={styles.noSuggested}>
                <div className={styles.containerImg}>
                    <img src="/Assets/images/NoSuggested.svg" alt="No hay viajes sugeridos." />
                    <div className={styles.header}>¡Kango aún sigue buscando tu viaje ideal!</div>
                    <div className={styles.desc}>
                        Tus viajes comenzarán a aparecer en aproximadamente <strong>30 minutos</strong>, puedes observar todos los viajes que se encuentran disponibles en &nbsp;
                         <span><Link to='/general-trips'>Viajes Generales</Link></span>.
                    </div>
                </div>
            </div>
        )
    }

    const renderBanner = ()=>{
        return(
            <div className={styles.bannerContainer}>
                <Banner 
            description={`La información de esta sección contiene únicamente embarques de Deacero del día de hoy.`}
            type="info"></Banner>
            </div>
        )
    }

    const loadTrips = async ()=>{
        const trips:suggestedTrips = await getSuggestedTrips()
        setRoutes(trips)
        if(Object.keys(trips).length == 0){
            setopenPopup(true);
        }
    }

    const getTripCardsByRoute = (routeKey:string):generalTrip[]=>{
        return routes[routeKey].map(trip=>{
            const generalTrip:generalTrip = {
                cityOrigin:trip.CityOrigin?.Name || "Error",
                cityDestiny:trip.CityDestiny?.Name  || "Error",
                unitType:trip.UnitType?.Description  || "Error",
                eta:trip.Eta || "Error",
                hasMultipleLocation:trip.MultipleLocation || false,
                hasReturn:trip.CloseCircuit,
                cityOriginState:trip.CityOrigin?.State,
                cityDestinyState:trip.CityDestiny?.State,
                id:trip.IdCarriage,
                carrier:trip.CupCarrier?.Name || "Not found",
                lastResponse:trip.LastResponse,
                infoUnitTypeRel:trip.infoUnitTypeRel,
            }
            return generalTrip
        }).filter(trip=>{
            if(!trip.id){
                console.error("Este viaje no cuenta con idCarriage", trip)
            }
            return trip.cityDestiny != "Not found" && trip.cityOrigin != "Not found" && trip.id
        })
    }

    const renderTrips = ()=>{
        const routesKeys:string[] = Object.keys(routes)
        if(routesKeys.length == 0) return 
        
        return routesKeys.map(routeKey => {
            const [routeOrigin,routeDestiny] = divideStringByFirstComma(routeKey)
            const tripCards = getTripCardsByRoute(routeKey)
            return(
                <SuggestedCard 
                key={routeKey} 
                origin={routeOrigin}
                destiny={routeDestiny}
                trips={tripCards}
                idLabel={routeKey}></SuggestedCard>
            )
        })
    }

    useAxiosNavigation();
    
    useEffect(() => {
        loadTrips()
    },[]);

    return(
        <>
            <div className={styles.containerRow}>
                <p className={styles.headerText}>Encuentra aquí los viajes activos de 
                Deacero que coinciden con tus rutas regulares. </p>
                {renderBanner()} 
                {renderTrips()}
                {noSuggested()}             
            </div>
            <PopUp closeButton={true} open={openPopup} onClose={() => {setopenPopup(false)}} content={popupContent} />
        </>
    )
}

export default SuggestedTrips;