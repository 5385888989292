import  React,{useState,useEffect,useContext} from 'react';
import styles from "./Disponibility.module.scss";

export interface disponibilityprops {
    lastResponse?:string |null,
    hasReturn?:boolean,
    hasMultipleLocation?:boolean,
}


const Disponibility:React.FC<disponibilityprops>=({
    lastResponse,
    hasReturn,
    hasMultipleLocation
})=>{
    
    const renderDisponibility = (label:string,description:React.ReactNode) =>{
        return(
            <div className={styles.disp}>
                 <div className={styles.header}>
                    {label}
                </div>
                <div className={styles.description}>
                    {description}
                </div>
            </div>
        )
    }
    const disponibility = ()=>{
        const label = "Disponible para"
        if(!lastResponse){
            return renderDisponibility(label,
                <>
                    Transportista no ha
                    <br />
                    respondido
                </>
            )
        }

        if(!hasReturn && hasMultipleLocation){
            return renderDisponibility(label,
                <>
                    Cerrar circuito y 
                    <br />
                    otro destino
                </>
            )
        }
        if(hasMultipleLocation){
            return renderDisponibility(label,
                <>
                    Otro destino
                </>
            )
        }
        if(!hasReturn){
            return renderDisponibility(label,
                <>
                    Cerrar circuito
                </>
            )
        }
        if(hasReturn && !hasMultipleLocation){
            return renderDisponibility("Disponible para",
                <>
                    No Disponible
                </>
            )
        }
        return <></>
    }

    return disponibility()
}

export default Disponibility;