import  React,{useState,useEffect,useContext, useRef} from 'react';
import styles from "./GeneralTrip.module.scss";
import { carriageDetail,product } from 'types';
import { Link, useNavigate } from "react-router-dom";
import {iTrip} from '../interface'
import { generalTripForms } from 'forms/FormsCatalogue';
import FormWithoutContext from "components/Form/FormWithoutContext";
import CCPForm from 'components/CCPForm';
import KGOButton from 'components/common/KGOButton';
import PopUp from 'components/common/Popup'
import { useLocation } from 'react-router-dom';
import { unstable_usePrompt } from 'react-router-dom';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { apiSaveDraft } from './GeneralTrip.service';
import { savePendingCarriage } from 'views/TripForm/TripsForm.service';
import HeaderCard from 'components/common/HeaderCard';
import { OptionsField } from 'forms/form.types';

export interface GeneralTripProps{
    trip:carriageDetail,
    getProducts?:(IdClientCompany:number)=>Promise<product[]>
}

const GeneralTrip: React.FC<GeneralTripProps>=({trip,getProducts})=>{
    const navigate = useNavigate();
    const generalTripStatusDesc = "";
    const clickGeneralTrips = ()=>{
        navigate(`/general-trips`);
    };
    const form = useForm()

    const [openPopup, setopenPopup] = useState(false);

    const [formState,setFormState] = useState<"saving" | "saved" | "filling">("filling")
    const formStateRef = useRef({})
    formStateRef.current = formState;
    const submitButtonText = trip.LastReponse ? "Enviar" : "En espera de respuesta"

    const popupContent = (
		<div className={styles.popup}>
		  <div className={styles["popup-heading"]}>
            ¡Tu solicitud ha sido enviada al transportista!
		  </div>
		  <div className={styles["popup-text"]}>
		  Te notificaremos en cuanto recibamos respuesta. Mientras tanto, puedes consultar tu solicitud en Viajes Pendientes.
		  </div>
		  <div className={styles.buttons}>
		  <KGOButton size='small'
           color='tertiary'
           emphasis='high' action={() => clickGeneralTrips()}>
		 	Ir a viajes generales
		  </KGOButton>
		  </div>
		</div>
	);


    const formIsDirty = () => {
        const { origin, originAddress, destiny, destinyAddress, carriageType, clientRoute, SpecialComments, rate } = form.getValues();
        return origin || originAddress || destiny || destinyAddress || SpecialComments || rate;
    };
    
    unstable_usePrompt({when:formIsDirty() && formState == "filling" ,message:`Al dar clic en editar después, ya no podrás seguir editando el formulario y se mantendrá en la sección de Borradores`,})

    const clearAsyncDropdowns = ()=>{
       let originField = generalTripForms[0].fields[0] as OptionsField
       if(originField.defaultAsyncValue) delete originField.defaultAsyncValue
       let detinyField = generalTripForms[1].fields[0] as OptionsField
       if(detinyField.defaultAsyncValue) delete detinyField.defaultAsyncValue
    }

    const preventUnload = (event: BeforeUnloadEvent) => {  
        if(formIsDirty() && formStateRef.current == "filling" ){
            const message = 'Sure you want to leave?';
            event.preventDefault();
            event.returnValue = message;
            alert("leave")
            saveDraft()
        }
    };

    useEffect(()=>{
        clearAsyncDropdowns()
        window.addEventListener('beforeunload', preventUnload);
        return ()=>{
            window.removeEventListener('beforeunload', preventUnload);
            if(formIsDirty() && formStateRef.current == "filling" ){
                saveDraft()
            }
        }
    },[formState])

    const savePending = () =>{
        const values = form.getValues()
        if(!values.CcpInfo || values.CcpInfo?.length  == 0){
            alert("Por favor agrega productos al viaje.")
            setFormState("filling")
            return
        }
        const carriageRequestBody={
            IdCarriage:trip.IdCarriage,
            CityOrigin:values.origin.value,
            AddressOrigin:values.originAddress,
            CityDestiny:values.destiny.value,
            AddressDestiny:values.destinyAddress,
            CarriageType:Number(values.carriageType),
            Weight:0,
            Rate:Number(values.rate),
            SpecialComments:values.SpecialComments,
            IdCarrier:trip.InfoCarrier? trip.InfoCarrier[0].IdCarrier : -1,
            IdRoute:Number(values.clientRoute),
            CcpInfo:values.CcpInfo.map((item:any)=>{
                return {
                    IdProduct: item.IdProduct,
                    ProductName:item.ProductName,
                    Mount: Number(item.Mount),
                    Weight: Number(item.Weight),
                    UnitValue:0
                }
            })
        }
        savePendingCarriage(carriageRequestBody).then((res:any)=>{
            setopenPopup(true)
            setFormState("saved")
        }).catch((error:any)=>{
            alert("Ha ocurrido un error al intentar guardar el viaje")
        })
    }

    const saveDraft = ()=>{
        const values = form.getValues()
        const carriageRequestBody:any={
            IdCarriage:trip.IdCarriage,
            CityOrigin:values.origin?.value,
            AddressOrigin:values.originAddress,
            CityDestiny:values.destiny?.value,
            AddressDestiny:values.destinyAddress,
            CarriageType:Number(values.carriageType),
            Weight:0,
            Rate:Number(values.rate),
            SpecialComments:values.SpecialComments,
            IdCarrier:trip.InfoCarrier? trip.InfoCarrier[0].IdCarrier : -1,
            IdRoute:Number(values.clientRoute),
            CcpInfo:values.CcpInfo.map((item:any)=>{
                return {
                    IdProduct: item.IdProduct,
                    ProductName:item.ProductName,
                    Mount: Number(item.Mount),
                    Weight: Number(item.Weight),
                    UnitValue:0
                }
            })
        }
        if(values.rate) carriageRequestBody.Rate = Number(values.rate)
        Object.keys(carriageRequestBody).forEach((key:any) => carriageRequestBody[key] === undefined ? delete carriageRequestBody[key] : {});
        apiSaveDraft(carriageRequestBody).then(response=>{
        }).catch(error=>{
            console.log("error al guardar draft",error)
        })
        
    }

    const onSubmit = async (event:any) => {
        event.preventDefault();

        const result:any = await form.trigger();
        if(result){
            setFormState("saving")
            savePending()
        }
    }

    const renderForms = ()=>{
        return(
            <>
                <FormWithoutContext style='KGO' 
                submitTitle='ssdfd' 
                inputForm={generalTripForms[0]}></FormWithoutContext>
                <FormWithoutContext style='KGO' 
                    submitTitle='ssdfd' 
                    inputForm={generalTripForms[1]}></FormWithoutContext>
                <FormWithoutContext style='KGO' 
                    submitTitle='ssdfd' 
                    inputForm={generalTripForms[2]}></FormWithoutContext>
                <FormWithoutContext style='KGO' 
                    submitTitle='ssdfd' 
                    inputForm={generalTripForms[3]}></FormWithoutContext>
                <CCPForm getProducts={getProducts}></CCPForm>
                <FormWithoutContext style='KGO' 
                    submitTitle='ssdfd' 
                    inputForm={generalTripForms[4]}></FormWithoutContext>
                <FormWithoutContext style='KGO' 
                    submitTitle='ssdfd' 
                    inputForm={generalTripForms[5]}></FormWithoutContext>
                <PopUp open={openPopup} onClose={() => {}} content={popupContent} />
            </>
        )
    }

    const renderForm = ()=>{
        return (
            
                <FormProvider {...form}>
                <form
                onSubmit={onSubmit}
                className={styles["form-container"]}
                >
                    <div className={styles.title}>
                        Formulario para el transportista
                    </div>
                    <>
                     {renderForms()}   
                     <div className={styles.buttonContainer}>
                        <KGOButton
                        emphasis="high"
                        color='primary'
                        type="submit"
                        disabled={formState == "filling" && trip.LastReponse ? false:true}
                        size={"medium"}>{formState == "filling" ? <>{submitButtonText}</> : <>Enviando</>}</KGOButton>
                     </div>
                    </>

                </form>
                </FormProvider> 
            
        )
    }

    const renderHeader = ()=>{
        return (
            <HeaderCard title='¿Te interesa este viaje?'>
                <strong>Para que el transportista pueda conocer 
                        el destino de tu interés</strong>, 
                    por favor llena y envía el formulario. Le enviaremos la información 
                    a través de WhatsApp para conocer su disponibilidad. 
            </HeaderCard>
        )
    }

    return(
        <>
            {renderHeader()}
            {renderForm()}
        </>
    )
}

export{ GeneralTrip };