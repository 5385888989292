
import {postRequest} from 'config/api/api';
import env from "react-dotenv";

export type SaveNewRatePending = {
    IdCarriage: number;
    Rate: number;
  };


  const savePendingCarriageNewRate = async(carriageRequest:SaveNewRatePending) =>{
    const url = `${env.REACT_APP_BACKEND}/save-pending-carriage`;
    const response = postRequest(url,carriageRequest)
    return response
}


export{
  savePendingCarriageNewRate
}

