import axios from "axios";

const fetchData = (url: string, params: any = {}) => {
    async function fetchUrl() {
        let token = localStorage.getItem("token") ? localStorage.getItem("token") : "";
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        const response = await axios.get(url + `?filter=${JSON.stringify(params)}`, config);
        //const json = await response.json();
        return response.data;
    }
    return fetchUrl();
};

const fetchDataDynamic = (url: string, filter: any = {}) => {
    async function fetchUrl() {
        let token = localStorage.getItem("token") ? localStorage.getItem("token") : "";
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        const response = await axios.get(url + `?filter=${filter}`, config);
        return response.data;
    }
    return fetchUrl();
};

const fetchDataNoFilter = (url: string) => {
    async function fetchUrl() {
        let token = localStorage.getItem("token") ? localStorage.getItem("token") : "";
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        const response = await axios.get(url, config);
        return response.data;
    }
    return fetchUrl();
};

const postRequest = async (url: string, body: any, bearer: boolean = true) => {
    let token = localStorage.getItem("token") ? localStorage.getItem("token") : "";
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            accept: "application/json",
            "Content-Type": "application/json",
        },
    };
    let response;
    if (bearer) {
        response = await axios.post(url, body, config);
    } else {
        response = await axios.post(url, body);
    }

    return response;
};

export { fetchData, postRequest, fetchDataNoFilter, fetchDataDynamic };
