import  React,{useState,useEffect,useContext} from 'react';
import styles from "./CCPForm.module.scss";
import { CartaPorte, product } from 'types';
import { DynamicDataContext } from "config/dynamicDataContext";
import { getProducts as getProductsApi } from './CCPForm.service';
import {useWatch,useFormContext,useFieldArray, useForm } from "react-hook-form";

interface CCPFormProps{
    cartaPorte?:CartaPorte[],
    disabled?:boolean,
    getProducts?:(IdClientCompany:number)=>Promise<product[]>
}

const CCPForm: React.FunctionComponent<CCPFormProps>=({
    cartaPorte,
    disabled = false,
    getProducts = getProductsApi
})=>{

    let methods = useFormContext()

    
    let clientRoute = useWatch({name:'clientRoute'})
    const dynamicOptionsContext = useContext(DynamicDataContext)
    const [products,setProducts] = useState<product[]>([])
    const fieldArray = useFieldArray({name:"CcpInfo"})
    

    const setFieldArrayFromProducts = ()=>{
        let values:any[] = []
        if(!cartaPorte) return null;
        cartaPorte.forEach(product=>{
            values.push({
                IdProduct:product.IdProduct,
                ProductName:product.ProductName,
                DisplayName:product.ProductName,
                Mount:product.Mount,
                UnitValue:product.UnitValue,
                Weight:product.Weight
            })
        })
        fieldArray.replace(values)
    }

    const fetchProducts = ()=>{
        const routesData = dynamicOptionsContext.dynamicData.clientRoutesData as Array<any>
        if(!routesData) return;
        const route = routesData.find((el:any) => el.IdRoute == clientRoute)
        if(route && route.IdClientCompany){
            const idCompany = route.IdClientCompany
            getProducts(idCompany).then(products =>{
                setProducts(products)
                let values:any[] = []
                products.forEach(p=>{values.push({
                    IdProduct:p.IdProduct,
                    ProductName:p.ProductName,
                    DisplayName:p.ProductName,
                    Mount:p.Mount,
                    UnitValue:p.UnitValue,
                    Weight:p.Weight
                })})
                fieldArray.replace(values)
            }).catch(er=>{
                alert("No se han podido obtener los productos CCP")
            })
        }
    }

    const isValidInput = (input:any) => input !== null && input !== undefined || input === 0;

    useEffect(()=>{
        if(!cartaPorte){
            fetchProducts()
        }else{
            setFieldArrayFromProducts()
        }
    },[clientRoute])

    const renderProduct = (product:any,index:any)=>{
       return(
        <div data-testid="ccp" key={product.IdProduct} className={styles.item}>
            <div className={styles.idContainer}>
                    {product.IdProduct}
            </div>
            <div className={styles.infoContainer}>
                <div className={styles.title}>
                    {product.DisplayName}
                </div>
                <div className={styles.detailsContainer}>
                    <div className={styles.detail}>
                        {isValidInput(product.UnitValue) &&
                            <>
                                <strong>U Medida</strong>:{product.UnitValue}
                            </>
                        }
                    </div>
                </div>
                    <div className={styles.itemFormContainer}>
                        {/*<div className={styles.labelWrapper}>
                            <label htmlFor="item">Item</label>
                            <div className={styles.inputWrapper}>
                                <input {...methods.register(`CcpInfo.${index}.ProductName`,{required:true})}
                                type="text" id="item" disabled={disabled}  placeholder="Placeholder"/>
                            </div>
                            {methods.formState.errors?.['CcpInfo']?.[index] && <div className={styles.warningText}>Este campo es requerido</div>}
                        </div>*/}
                        <div className={styles.labelWrapper}>
                            <label htmlFor="item">Cantidad</label>
                            <div className={styles.inputWrapper}>
                                <input {...methods.register(`CcpInfo.${index}.Mount`,{required:true})}
                                defaultValue={""}
                                type="number" id="item" disabled={disabled}  placeholder="0" min="1" />
                            </div>
                            {methods.formState.errors?.['CcpInfo']?.[index] && <div className={styles.warningText}>Este campo es requerido</div>}
                        </div>
                        <div className={styles.labelWrapper}>
                            <label htmlFor="item">Peso</label>
                            <div className={styles.inputWrapper}>
                                <input {...methods.register(`CcpInfo.${index}.Weight`,{required:true})} 
                                type="number" min="1" id="item" disabled={disabled}  placeholder="0000"/>
                                <div className={styles.suffix}>kg</div>
                            </div>
                            {methods.formState.errors?.['CcpInfo']?.[index] && <div className={styles.warningText}>Este campo es requerido</div>}
                        </div>
                    </div>
            </div>
        </div>
       )
    }

    const renderProducts = () =>{
        return fieldArray.fields.map((item,index)=>{
            return renderProduct(item,index)
        })
    }



    return(
        <>
            <div className={styles.itemsContainer}>
                {renderProducts()}
            </div>
        </>
    )
}

export default CCPForm;