import React, { useState } from "react";
import styles from "./ContactForm.module.scss";
import Banner from "components/common/Banner";
import { useForm, FormProvider } from "react-hook-form";
import KGOButton from "components/common/KGOButton";
import Select from "../../Form/Select";
import { apiSaveInfoCarrier as saveInfoCarrierDefault, InfoCarrier } from "../CarrierInfoBar.service";

interface ContactFormProps {
  whatsappOptions: any;
  mainCarrier: any;
  mainCarrierIndex: number;
  idCarrier: any;
  idCarriage: number;
  lastResponse: any;
  onOpen: () => void;
  apiSaveInfoCarrier?:(infoCarriers:InfoCarrier[]) => Promise<any>,
}

const ContactForm: React.FC<ContactFormProps> = ({
  whatsappOptions,
  mainCarrier,
  mainCarrierIndex,
  idCarrier,
  idCarriage,
  lastResponse,
  onOpen,
  apiSaveInfoCarrier = saveInfoCarrierDefault,
}) => {
  const methods = useForm({ mode: "onChange" });
  const warningBannerTetx = `Ya se contactó a todas las personas listadas a continuación. Es necesario agregar un nuevo contacto para que reciba la información.`;
  const infoBannerText =
    "Los datos se agregaron con éxito y consultamos disponibilidad con el transportista. Te notificaremos en cuanto recibamos respuesta.";

  const [contactUploaded, setContactUploaded] = useState<boolean>(false);
  const [showAddContactForm, setShowContactForm] = useState<boolean>(false);
  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);

  const openContactform = () => {
    setShowContactForm(true);
  };

  const addContact = async () => {
    const isvalid = await methods.trigger();
    if (isvalid) {
      const values = methods.getValues();
      const body: InfoCarrier[] = [
        {
          IdCarrier: idCarrier ? Number(idCarrier) : -1,
          Name: values.carrierName,
          Phone: values.carrierWhatsapp,
          Email: " ",
          Main: true,
          IdCarriage: idCarriage,
        },
      ];
      setIsCallingApi(true);
      apiSaveInfoCarrier(body)
        .then(() => {
          onOpen();
          setContactUploaded(true);
          setIsCallingApi(false);
        })
        .catch(() => {
          setIsCallingApi(false);
          alert("Ha ocurrido un error al guardar el contacto.");
        });
    }
  };

  const renderContactFields = () => {
    if (!lastResponse) {
      return (
        <>
          {showAddContactForm == false &&
            contactUploaded == false &&
            !mainCarrier.Main && (
              <div onClick={openContactform} className={styles.addContactTitle}>
                + Agregar nuevo contacto
              </div>
            )}
          {showAddContactForm && contactUploaded == false && (
            <>
              <div className={styles.divider} />
              <div className={styles.formTitle}>
                Agrega los datos de contacto
              </div>
              <div className={styles.inputWrapper}>
                <input
                  {...methods.register(`carrierName`, { required: true })}
                  type="text"
                  id="item"
                  placeholder="Nombre transportista"
                />
              </div>
              {methods.formState.errors?.["carrierName"] && (
                <div className={styles.warningText}>
                  Este campo es requerido
                </div>
              )}
              <div className={styles.inputWrapper}>
                <input
                  {...methods.register(`carrierWhatsapp`, {
                    required: true,
                    pattern: /^[0-9]{10}$/,
                  })}
                  type="text"
                  id="item"
                  placeholder="Whatsapp"
                />
              </div>
              {methods.formState.errors?.["carrierWhatsapp"]?.type ==
                "required" && (
                <div className={styles.warningText}>
                  Este campo es requerido
                </div>
              )}
              {methods.formState.errors?.["carrierWhatsapp"]?.type ==
                "pattern" && (
                <div className={styles.warningText}>
                  Escribe el WhatsApp en formato de 10 dígitos
                </div>
              )}
              <KGOButton
                action={addContact}
                size="small"
                color="tertiary"
                emphasis="high"
                disabled={isCallingApi}
              >
                {isCallingApi
                  ? "Enviando Solicitud"
                  : "Consultar Disponibilidad"}
              </KGOButton>
            </>
          )}
        </>
      );
    }
  };

  const renderPhoneNumbers = ()=>{
    return(
      <div className={styles.phoneWrapper}>
        {
          whatsappOptions.map((phone:any)=>{
            return <>
              <div className={styles.phone}>
                {phone.label}
                <br />
              </div>
            </>
          })
        }
      </div>
    )
  }

  return (
    <>
      {lastResponse === null ? (
        <>
          {!lastResponse && contactUploaded == false && !mainCarrier.Main && (
            <div className={styles.bannerContainer}>
              <Banner type="error" description={warningBannerTetx}></Banner>
            </div>
          )}
          {contactUploaded && (
            <Banner
              title="¡Tu solicitud ha sido enviada al transportista!"
              type="success"
              description={infoBannerText}
            ></Banner>
          )}
          <div className={styles.contactForm}>
            <div className={styles.title}>Contacto</div>
            {renderPhoneNumbers()}
            {renderContactFields()}
          </div>
        </>
      ) : (
        <>
          <div className={styles.contactForm}>
            <div className={styles.title}>Contacto</div>
            {renderPhoneNumbers()}
          </div>
        </>
      )}
    </>
  );
};

export default ContactForm;
