import { OptionsField } from "forms/form.types";
import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import styles from "./TextFieldValidator.module.scss";

const TextField: React.FC<OptionsField> = ({
  name,
  value,
  placeholder,
  label,
  required,
  type,
  disabled,
  validatorFunction,
  validatorErrorMessage = "Invalido",
  validatorSucessMessage = 'Valido'
}) => {
  const methods = useFormContext();
  const [validated,setValidated] = useState<boolean>(false)

  const requiredMessage = methods.formState.errors[name] && (
    <span className={styles["error-text"]}>Este campo es requerido</span>
  );

  const validatorMessage = methods.formState.errors[name+"validator"] && !methods.formState.errors[name] && (
    <span className={styles["error-text"]}>{validatorErrorMessage}</span>
  );
  const isValid = validated && (
    <span className={styles["sucess-text"]}>{validatorSucessMessage}</span>
  )


  const handleChange = async (event:any)=>{
    const value = event.target.value;
    methods.setValue(name, value);
    const isValid = validatorFunction? await validatorFunction(value) : false
    setValidated(isValid)
    if(isValid){
      methods.clearErrors(name+"validator")
    }else{
      methods.setError(name+"validator",{type:"validator",message:validatorErrorMessage})
    }    
    methods.trigger(name)
  }


  return (
    <div className={styles.KGOContainer}>
      <label className={styles.KGOLabel}>{label}</label>
      <input
        {...methods.register(name, { value,required:required })}
        className={`${styles.KGOInput} ${styles[styles.KGOErrorStyle]}`}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        onChange={(e) => {
          handleChange(e)
        }}
      />
      {requiredMessage}
      {validatorMessage}
      {isValid}
    </div>
  );

};

export default TextField;
