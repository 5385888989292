import styles from "./Button.module.scss";

interface ButtonProps {
  children?: React.ReactNode;
  color?:
    | "grey"
    | "primary"
    | "primary-filled"
    | "secondary"
    | "secondary-filled"
    | "no-color"
    | "black";

  action: (data?: any) => void;
  disable?: boolean;
  border?: boolean;
  variant?: "regular" | "square";
  isFormButton?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  color = "default",
  action,
  disable = false,
  border = true,
  variant = "regular",
  isFormButton = false,
}) => {
  const isBasicColor =
    color === "grey" ||
    color === "primary" ||
    color === "primary-filled" ||
    color === "secondary" ||
    color === "secondary-filled";
  const HoverEffect = isBasicColor ? "hover" : "";
  const buttonDisabled = disable ? "disable" : color;
  const buttonBorder = border ? "" : "no-border";
  const buttonForm = isFormButton ? "form-button": "";

  return (
    <button
      disabled={disable}
      className={`${styles.button} ${styles[buttonDisabled]} ${styles[variant]} ${styles[buttonBorder]} ${styles[HoverEffect]} ${styles[buttonForm]}`}
      onClick={action}
    >
      {children}
    </button>
  );
};

export default Button;
