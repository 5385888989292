import  React,{useState,useEffect,useContext} from 'react';
import styles from "./SuggestedCard.module.scss";
import { useNavigate } from "react-router-dom";
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faTruckArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TripRow from 'components/TripRow';
import { generalTrip } from 'types';


export interface SuggestedCardProps {
idLabel:number | string,
origin:string,
destiny:string,
trips:generalTrip[]
}


const SuggestedCard: React.FC<SuggestedCardProps>=({
 idLabel,
 origin,
 destiny,
 trips
})=>{
    const label = "label"+idLabel.toString();
    const navigate = useNavigate();

    const renderTrips =  
    trips.map(trip=>{
    return <TripRow key={trip.id} type="suggested" trip={trip}></TripRow>
     }) 

    return(
        <>
           <div className={styles['card']}>
				<input name="expandable" id={label} type="checkbox" 
					className={styles["expandableToggler"]}/>
				<div className={styles['cardInfo']}>
					<label htmlFor={label} className={styles["pointer"]}>
						<div className={styles['headerCard']}>
						<div className={styles['header']}>
							Encontramos {trips.length} viaje que coincide con esta ruta
						</div>
						<div className={styles["infoContainer"]}>
							<div className={styles['origin']}>
								<div className={styles['iconContainer']}>
								<FontAwesomeIcon className={styles.icon} icon={faLocationDot} />
								</div>
								<div className={styles['originInfo']}>
									<div className={styles["originHeader"]}>Origen</div>
									<div className={styles["originText"]}>{origin}</div>
								</div>
							</div>
							<div className={styles['destiny']}>
								<div className={styles['iconContainer']}>
								<FontAwesomeIcon className={styles.icon} icon={faTruckArrowRight} />
								</div>
								<div className={styles['originInfo']}>
									<div className={styles["originHeader"]}>Destino</div>
									<div className={styles["originText"]}>{destiny}</div>
								</div>
							</div>
						</div>
						<div className={styles["arrowContainer"]}>
							<div className={styles['arrow']}>
							</div>
						</div>
					</div>
					</label>
					<div className={styles['content']}>
						{renderTrips}
					</div>
				</div>
		   </div>
        </>
    )
}

export default SuggestedCard;