import  React,{useState,useEffect,useContext} from 'react';
import styles from "./Pending.module.scss";
import Sidebar from 'components/common/Sidebar';
import UserContext from "config/userContext";
import { useNavigate } from "react-router-dom";
import Indicators from 'components/Indicators';
import TripRow from 'components/TripRow';
import { generalTrip } from 'types';
import { getPendingTrips as getPendingTripsApi } from './Pending.service';
import ImageHeader from 'components/common/ImageHeader';
import Banner from 'components/common/Banner';
import EmptyTrip from 'components/common/EmptyTrip';
import {useAxiosNavigation} from "config/api/AxiosInterceptor";
import { daysDifference } from 'components/common/Utils/utils';

export interface PendingProps{
    getPendingTrips?:()=>Promise<generalTrip[]>
}

const Pending: React.FunctionComponent<PendingProps>=({
    getPendingTrips = getPendingTripsApi
})=>{
    const navigate = useNavigate();
    const { userData, setUserData } = useContext(UserContext);
    const [trips, setTrips] = useState<generalTrip[]>([]);
    const breadCrumbData = [
        {label:"Mis Viajes",linkTo:"/my-trips"},
        {label:"Pendientes",linkTo:"#"},
    ]

    useAxiosNavigation();

    useEffect(() => {
        getPendingTrips().then(trips=>{
            setTrips(trips)
        }) 
    }, [userData, navigate]);

    const renderTrips =  
        trips.map(trip=>{
        return <TripRow  key={trip.id} type="pending" trip={trip}></TripRow>
    })  

    const renderBanner = ()=>{
        const tripsToExpire = countTripsAboutToExpire();
        if(tripsToExpire <=0) return;
        return(
            <div className={styles.bannerContainer}>
                <Banner 
            description={`Tienes ${tripsToExpire} viaje(s) que está por caducar, recuerda que los viajes caducados se eliminan automáticamente.`}
            type="warning"></Banner>
            </div>
        )
    }

    const countTripsAboutToExpire = ()=>{
        let counter = 0
        trips.map(trip=>{
            if(trip.eta){
                const eta = new Date(trip.eta)
                const days = daysDifference(eta,new Date())
                if(days >=-2){
                    counter ++
                }
            }
        })
        return counter
    }

    

    const noCompleted = ()=>{
        if(trips.length > 0) return
        return(
            <EmptyTrip header='¡Por el momento no tienes ningún viaje pendiente!' sourceImg='/Assets/images/NoPending.svg'>
                Mientras tanto, puedes observar los viajes disponibles que tenemos en Viajes Generales
                ó los viajes que te sugerimos según tus rutas actuales en Viajes Sugeridos. 
            </EmptyTrip>
        )
    }

    return(
        <>
            <Sidebar>
            <ImageHeader title="Pendientes" 
            breadCrumb={breadCrumbData}
            imgPath="Assets/images/TruckWithContainer.png" ></ImageHeader>
            <div className={styles.container}>
                {renderBanner()}
                <div className={styles.headerText}>Encuentra aquí tus viajes solicitados en espera de respuesta por parte del transportista.  </div>
                {noCompleted()}
                {renderTrips}
            </div>
            </Sidebar>
        </>
    )
}

export default Pending;