import  React,{useState,useEffect,useContext} from 'react';
import styles from "./Indicators.module.scss";
import Sidebar from 'components/common/Sidebar';
import UserContext from "config/userContext";
import { useNavigate,Link } from "react-router-dom";
import { faEraser } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { faGear } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'




const Indicators: React.FunctionComponent=()=>{
    const navigate = useNavigate();
    const { userData, setUserData } = useContext(UserContext);

    const clickNavigate = (path:string)=>{
        navigate(path)
    }

    const erasers = ()=>{
        return(
            <div className={styles.card} data-testid="draft" onClick={()=>{clickNavigate("/erasers")}}>
                <div>
                <div className={styles.iconContainer}>
                    <FontAwesomeIcon 
                    className={styles.icon} icon={faEraser} /></div>
                </div>  
                <div>
                    <div className={styles.title}>
                        <div className={styles.titleText}>Borradores</div>
                    </div>
                    <div className={styles.subtitle}>
                        {/* <div className={styles.indicator}>
                            0
                        </div> */}
                        <div className={styles.text}>
                            Viajes incompletos
                            <br />
                            de información
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <>
            <div className={styles.container}>
                {erasers()}

                <div className={styles.card} data-testid="pending" onClick={()=>{clickNavigate("/pending")}}>
                    <div>
                        <div className={styles.iconContainer}>
                            <FontAwesomeIcon
                            className={styles.icon} icon={faClock} />
                        </div>
                    </div>  
                    <div>
                        <div className={styles.title}>
                            <div className={styles.titleText}>Pendientes</div>
                        </div>
                        <div className={styles.subtitle}>
                            {/* <div className={styles.indicator}>
                                0
                            </div> */}
                            <div className={styles.text}>
                            Viajes en espera de respuesta.
                            </div>
                        </div>
                    </div>
                </div>

{/* contact card */}
                {/* <div className={styles.card}>
                    <div>
                        <div className={styles.iconContainer}>
                            <FontAwesomeIcon onClick={()=>{return}} 
                            className={styles.icon} icon={faClock} />
                        </div>
                    </div>  
                    <div>
                        <div className={styles.title}>
                            <div className={styles.titleText}>Contacto</div>
                        </div>
                        <div className={styles.subtitle}> */}
                            {/* <div className={styles.indicator}>
                                0
                            </div> */}
                            {/* <div className={styles.text}>
                            Guarda o cambia
                            <br />
                            tus datos
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    )
}
<div className={styles.iconContainer}><FontAwesomeIcon className={styles.icon} icon={faGear} /></div>
export default Indicators;