import { Option, OptionsField } from "forms/form.types";
import { useContext, useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import styles from "./Dropdown.module.scss";
import AsyncSelect from 'react-select/async';
import { fetchDataDynamic } from "config/api/api";
import { useForm, FormProvider, useFormContext, Controller } from "react-hook-form";
import env from "react-dotenv";

const Dropdown: React.FC<OptionsField> = ({
  name,
  label,
  value,
  required,
  disabled,
  options,
  // dynamicOptionsProp,
  optionsContext,
  placeholder="Select ...",
  style="MKT",
  async = false,
  asyncSearcher,
  isMulti,
  defaultAsyncValue,
  optionGetter
}) => {
  const methods = useFormContext();
  const {formState:{errors} , control, trigger} = useFormContext()

    // ? dynamicOptionsContext?.["dynamicData"]?.[dynamicOptionsProp]
    // : options;
  const defaultValue = value;

  const [currentValue, setCurrentValue] = useState(defaultValue);
  const [availableOptions,setAvailableOptions] = useState(options);

  useEffect(()=>{
    if(optionGetter){
      optionGetter().then(options=>{
        setAvailableOptions(options)
      })
    }
  },[optionGetter])

  

  const renderOptions =
    availableOptions?.length &&
    availableOptions.map((option: Option) => {
      return (
        <option data-testid="select-option-label" key={option.value} value={option.value}>
          {option.label}
        </option>
      );
    });

  const loadOptions = (inputValue:string, callback:any) => {
    let url = `${env.REACT_APP_BACKEND}/cat-cities`;
    let filter = "---"
    if(asyncSearcher==="city"){
      url = `${env.REACT_APP_BACKEND}/cat-cities`;
      filter = `%7B%0A%20%20%22limit%22%3A%2010%2C%0A%20%20%22where%22%3A%7B%0A%20%20%20%20%22Name%22%3A%7B%0A%20%20%20%20%20%20%22ilike%22%3A%22%25${inputValue}%25%22%0A%20%20%20%20%20%7D%0A%20%20%7D%0A%7D`
    }
    return fetchDataDynamic(url,filter).then(res=>{
      return res.map((city:any)=>{
        return {label:`${city.Name}, ${city.State}`,value:city.IdCity}
      })
    })
  }

  const handleChange = () => {
    change()
  };

  const change = async () => {
    await trigger(name)
  };

    if(style==="KGO" && async == false){
      return (
        <div className={styles.KGOcontainer}>
          <label className={styles.KGOLabel}>{label}</label>
          <div className={styles["KGO-input-container"]}>
            <select data-testid="select-option"
                {...methods.register(name, {  required })}
                disabled={disabled}
                onChange={(e) => {
                  const value = e.target.value;
                  methods.setValue(name, value);
                  if (value !== currentValue) {
                    setCurrentValue(value);
                  }
                }}
              >
                {renderOptions}
            </select>
            <div className={styles.KGOarrow}>
              <ArrowDropDownIcon />
            </div>
          </div>
        </div>
      );
    }

    if(style==="KGO" && async){
      return (
        <>
          <span
            className="d-inline-block"
            data-toggle="popover"
            data-trigger="focus"
            data-content="Please selecet account(s)"
          >
            <Controller
            name={name}
            control={control}
            rules={required ? { required: 'Este campo es requerido' } : {}}
            render={({ field }) => (
              <AsyncSelect
                className={styles.asyncSelect}
                loadOptions={loadOptions}
                isClearable
                value={field.value}
                onChange={field.onChange}
                onInputChange={handleChange}
                ref={field.ref}
                defaultInputValue={defaultAsyncValue}
                placeholder={placeholder}
                options={options}
                isMulti={isMulti}
                closeMenuOnSelect={!isMulti}
                hideSelectedOptions={true}
                classNamePrefix={styles["react-select"]}
              />
            )}
          />
          {errors[name] && <div className={styles.warningText}>Este campo es requerido</div>}
          </span>
        </>
      );
    }

  return (
    <div className={styles.container}>
      <label>{label}</label>
      <div className={styles["input-container"]}>
        <select data-testid="select-option"
          {...methods.register(name, { value, required })}
          defaultValue={currentValue}
          disabled={disabled}
          onChange={(e) => {
            const value = e.target.value;
            setCurrentValue(value);
          }}
        >
          {renderOptions}
        </select>
        <div className={styles.arrow}>
          <ArrowDropDownIcon />
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
