import  React,{useState,useEffect,useContext} from 'react';
import styles from "./Sidebar.module.scss";

import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faTruckArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserContext from "config/userContext";
import { useNavigate } from "react-router-dom";
import { getInitials } from '../Utils/utils';

export interface SideBarTripsProps {
    children?: React.ReactNode;
  }

  const SideBar: React.FC<SideBarTripsProps> = (
    {
      children
    }
  ) =>{
    const navigate = useNavigate();
    const clickNavigate = (path:string)=>{
        navigate(path)
    }
    const { userData, setUserData } = useContext(UserContext);

    const capitalize = (string:string | undefined)=>{
        if(string){
            return string.charAt(0).toUpperCase() + string.slice(1);
        }else{
            return " "
        }
    }

    const firstName = capitalize(userData.Username?.split(' ').shift()?.toLowerCase())


    const path:string = window.location.pathname;
    const initials = getInitials(userData.Username)

    const navItemsArray=[
        {
            path: "/my-trips",
            itemjsx: <div className={styles.text}><span><FontAwesomeIcon className={styles.icon} icon={faUser} /></span>Mis Viajes</div>
        },
        {
            path:"/general-trips",
            itemjsx:<div className={styles.text}><span><FontAwesomeIcon className={styles.icon} icon={faTruckArrowRight} /></span>Viajes Generales</div>
        }
    ]

    const itemsDisplay = navItemsArray.map(item=>{
        if (path=== item.path){
            return (
                <div key={item.path} className={styles.itemActive}>
                    {item.itemjsx}     
                </div>
            )
        }else{
            return(
                <div key={item.path} onClick={()=>{clickNavigate(item.path)}}>
                    <div className={styles.item}>
                    {item.itemjsx}      
                    </div>
                </div>
            )
        }
    })
    
    return(
        <>
            <div className={styles.sidebar}>
                <div className={styles.up}>
                    <div className={styles.profileImg}>
                        <div className={styles.profileImgText}>{initials}</div>
                    </div>
                    <div className={styles.upTitle}>Hola, {firstName}</div>
                </div>
                <div className={styles.mid}>
                    <div className={styles.navList}>
                        {itemsDisplay}
                    </div>
                </div>
                <div className={styles.down}>
                <img src="/kango-logo-BGonDark.svg" alt="Kango logo" />
                </div>
            </div>
            <div className={styles.content}>
                {children}
            </div>
        </>
    )
}

export default SideBar;