import  React,{useState,useEffect,useContext, useRef} from 'react';
import styles from "./TripForm.module.scss";
import Sidebar from 'components/common/Sidebar';
import UserContext from "config/userContext";
import { useNavigate } from "react-router-dom";
import ImageHeader from 'components/common/ImageHeader';
import { Form as FormType } from "forms/form.types";
import FormWithoutContext from "components/Form/FormWithoutContext";
import { FieldValues, FormProvider, useForm,useWatch } from "react-hook-form";
import { generalTripForms } from 'forms/FormsCatalogue';
import { format } from 'path';
import Button from "components/common/Button";
import Banner from 'components/common/Banner';
import ActiveTrip from 'components/ActiveTrip';
import CarrierInfoBar from 'components/CarrierInfoBar';
import { useParams } from 'react-router-dom';
import img from "Images/CargoContainer.png";
import { getTripDetail as getTripDetailApi, getClientRoutes as getClientRoutesApi} from './TripsForm.service';
import { carriageDetail,carriageType,clientRoutes } from 'types';
import { createContext } from 'react';
import { DynamicDataProvider } from "config/dynamicDataContext";
import KGOButton from 'components/common/KGOButton';
import PopUp from 'components/common/Popup'
import CCPForm from 'components/CCPForm';
import { OptionsField } from 'forms/form.types';
import { Option } from 'forms/form.types';
import {AcceptedTrip, CompletedTrip} from 'components/TripForms';
import {GeneralTrip} from 'components/TripForms';
import HeaderCard from 'components/common/HeaderCard';
import { render } from '@testing-library/react';
import { Draft } from 'components/TripForms/Draft';
import { PendingTrip } from 'components/TripForms/Pending';
import { RejectedTrip } from 'components/TripForms/Rejected/RejectedTrip';
import {useAxiosNavigation} from "config/api/AxiosInterceptor";

export interface TripFormProps {
    getTripDetail?: (idTrip: number) => Promise<carriageDetail>;
    getClientRoutes?: () => Promise<clientRoutes[]>;
}

const TripForm: React.FunctionComponent<TripFormProps>=({
    getTripDetail = getTripDetailApi,
    getClientRoutes = getClientRoutesApi
})=>{
    const navigate = useNavigate();
    const { userData, setUserData } = useContext(UserContext);
    const [trip, setTrip] = useState<carriageDetail>({IdCarriage:-1})
    const { idTrip } = useParams();
    const [dynamicData,setDynamicData] = useState<any>({"dynamicData":{}});
    const tripRef = useRef(trip)
    tripRef.current = trip
    const generalTripStatusDesc = "";
    const {type} = useParams()

    let submitButtonText:string
    let breadCrumbData:any = type == "suggested" ? 
    [
        {label:"Mis viajes",linkTo:"/my-trips"},
        {label:"Viajes Sugeridos",linkTo:"/my-trips/suggested"},
        {label:"Viaje",linkTo:"#"}
    ]
    :
    [
        {label:"Viajes Generales",linkTo:"/General-trips"},
        {label:"Viaje",linkTo:"#"}
    ]

    switch (trip.Status) {
        case "Nuevo":
          submitButtonText = "Enviar"
          break;
        case "Pendiente":
            submitButtonText = "Enviar"
            breadCrumbData = [
                {label:"Mis Viajes",linkTo:"/my-trips"},
                {label:"Pendientes",linkTo:"/pending"},
                {label:"Viaje",linkTo:"#"}
            ]
          break;
        case "Rechazado":
            submitButtonText = "Enviar"
            breadCrumbData = [
                {label:"Mis Viajes",linkTo:"/my-trips"},
                {label:"Rechazados",linkTo:"/my-trips/rejected"},
                {label:"Viaje",linkTo:"#"}
            ]
          break;
        case "Completado":
            submitButtonText = "Viaje Completado"
            breadCrumbData = [
                {label:"Mis Viajes",linkTo:"/my-trips"},
                {label:"Viajes Completados",linkTo:"/my-trips/completed"},
                {label:"Completar viaje",linkTo:"#"}
            ]
          break;
        case "Borrador":
            submitButtonText = "Enviar"
            breadCrumbData = [
                {label:"Mis Viajes",linkTo:"/my-trips"},
                {label:"Borradores",linkTo:"/erasers"},
                {label:"Completar viaje",linkTo:"#"}
            ]
          break;
        case "Aceptado":
            submitButtonText = "Enviar"
            breadCrumbData = [
                {label:"Mis Viajes",linkTo:"/my-trips"},
                {label:"Viajes Aceptados",linkTo:"/my-trips/accepted"},
                {label:"Viaje",linkTo:"#"}
            ]
          break;
        case null:
        case undefined:
            submitButtonText = "Enviar"
          break;
        default:
          // handle unexpected values
          break;
      }

    let loadComponent = async () => {
          if (idTrip == undefined){
              navigate(`/login`);
              return;
          }else{
              if(tripRef.current.IdCarriage == -1){
                let carriageDetailResponse = await getTripDetail(parseInt(idTrip))
                console.log(carriageDetailResponse)
                 setTrip(carriageDetailResponse)
              }

              let clientRoutesResponse = await getClientRoutes()
              let current = dynamicData;
              current["clientRoutesData"] = clientRoutesResponse; 
              setDynamicData(current)
          }
    }

    useAxiosNavigation();

    useEffect(() => {
        loadComponent()
    }, []);

    const renderForm = ()=>{
        
        if(trip.Status == generalTripStatusDesc){
            return <GeneralTrip trip={trip}></GeneralTrip>;
        }
        if(trip.Status === 'Aceptado'){
            return <AcceptedTrip trip={trip} ></AcceptedTrip>;
        }
        if(trip.Status == "Completado"){
            return <CompletedTrip trip={trip} ></CompletedTrip>;
        }
        if(trip.Status == "Borrador"){
            return <Draft trip={trip}></Draft>
        }
        if(trip.Status =="Pendiente"){
            return <PendingTrip trip={trip}></PendingTrip>
        }
        if(trip.Status == "Rechazado"){
            return <RejectedTrip trip={trip}></RejectedTrip>
        }
    }

    const renderActiveTrip = ()=>{
        if(trip.Status == "Completado"){
            return <></>
        }
        return(
            <>
                <ActiveTrip trip={trip}></ActiveTrip>
            </>
        )
    }

    return(
        <>
        <DynamicDataProvider value={{dynamicData,setDynamicData}}>
            <Sidebar>
            {trip.IdCarriage != -1 &&(
                <>
                    <ImageHeader title=" " 
                    imgPath={img} breadCrumb={breadCrumbData} ></ImageHeader>
                    <div className={styles.divider}>
                        <div className={styles.columnOne}>
                            {renderForm()}
                        </div>
                        <div className={styles.columnTwo}>
                            {trip.Status != "Completado" && 
                                <div className={styles.banner}>
                                <Banner 
                                type="warning"
                                description={"La información que se muestra en esta sección corresponde a un viaje activo de Deacero."}
                                title="Recuerda"></Banner>
                                </div>
                            }
                            <div className={styles.tripInfoContainer}>
                                    {trip.Status != "Completado" && 
                                        <div className={styles.activeTripHeader}>
                                        Despliega para ver el viaje  <br /> en tránsito de Deacero.
                                        </div>
                                    }
                                {renderActiveTrip()}
                                <CarrierInfoBar trip={trip}></CarrierInfoBar>
                            </div>
                        </div>
                    </div>
                </>
            )}
            </Sidebar>
        </DynamicDataProvider>
        {/* <PopUp open={openPopup} onClose={() => {}} content={popupContent} /> */}
        </>
    )
}

export default TripForm;