import  React,{useState,useEffect,useContext} from 'react';
import styles from "./MyTrips.module.scss";
import Sidebar from 'components/common/Sidebar';
import UserContext from "config/userContext";
import { useNavigate,Link, useParams } from "react-router-dom";
import Indicators from 'components/Indicators';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Trips from "components/Trips";
import SuggestedTrips from "components/SuggestedTrips";
import {useAxiosNavigation} from "config/api/AxiosInterceptor";
import {TripsProps } from 'components/Trips/Trips';
import { getAcceptedTrips, getCompletedTrips, getRejectedTrips } from './MyTrips.service';
import { SuggestedTripsProps } from 'components/SuggestedTrips/SuggestedTrips';


const completedTripsPropsDefault : TripsProps = {
    emptyTripHeader: '¡Aún no tienes viajes completados en Kango!',
    headerText: "Encuentra aquí todos los viajes que has completado dentro de Kango.",
    emptyTripSourceImg: '/Assets/images/NoCompleted.svg',
    emptyTripChildren:<> Si ya cuentas con Viajes Aceptados disponibles, 
    selecciona uno y realiza los pasos necesarios para completarlo.</>,
    tripSearcher:getCompletedTrips
}

const rejectedTripsPropsDefault : TripsProps = {
    emptyTripHeader: '¡Excelente! No tienes ningún viaje rechazado.',
    headerText: "Encuentra aquí todos los viajes que se han rechazado.",
    emptyTripSourceImg: '/Assets/images/NoCompleted.svg',
    emptyTripChildren:<> Te invitamos a seguir encontrando viajes visitando  Viajes Generales ó Viajes Sugeridos.</>,
    tripSearcher:getRejectedTrips
}

const acceptedTripsPropsDefault : TripsProps = {
    emptyTripHeader: '¡Aún no tienes viajes aceptados en Kango!',
    headerText: "Encuentra aquí todos los viajes solicitados que ya fueron aceptados por el transportista.",
    emptyTripSourceImg: '/Assets/images/NoCompleted.svg',
    emptyTripChildren:<> Puedes consultar tus Viajes Sugeridos o 
    <span><Link to='/general-trips'> Viajes Generales </Link></span>
    para seleccionar uno e iniciar el proceso.</>,
    tripSearcher:getAcceptedTrips
}

const enum tabValueType{
        suggested = 0,
        accepted = 1,
        rejected = 2,
        completed = 3
    }

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export interface MyTripsProps{
    completedTripsProps?:TripsProps;
    rejectedTripsProps?:TripsProps;
    acceptedTripsProps?:TripsProps;
    suggestedTripsProps?:SuggestedTripsProps;
}

const MyTrips: React.FunctionComponent<MyTripsProps>=({
    completedTripsProps = completedTripsPropsDefault,
    rejectedTripsProps = rejectedTripsPropsDefault,
    acceptedTripsProps = acceptedTripsPropsDefault,
    suggestedTripsProps 
})=>{

    const {type} = useParams()
    let initialTabValue = 0
    if(type=="suggested") initialTabValue = tabValueType.suggested
    if(type=="accepted") initialTabValue = tabValueType.accepted
    if(type=="rejected") initialTabValue = tabValueType.rejected
    if(type=="completed") initialTabValue = tabValueType.completed

    const [tabValue, setTabValue] = React.useState(initialTabValue);
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
      };

    useAxiosNavigation();
    
    function a11yProps(index: number) {
        return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
    }

    const label = (label:string)=>{
        return label
    }

    const TabIndicatorProps={style: {background:"#FF6116" }}
    
  
    const renderTabs = ()=>{
        return(
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1 }}>
                    <Tabs sx={{ color: '#696969' }}
                        value={tabValue}
                        onChange={handleTabChange} 
                        centered={true}
                        TabIndicatorProps={TabIndicatorProps}
                        aria-label="basic tabs example">
                    <Tab label={label("Viajes Sugeridos")} {...a11yProps(tabValueType.suggested)} />
                    <Tab label={label("Viajes Aceptados")} {...a11yProps(tabValueType.accepted)} />
                    <Tab label={label("Viajes Rechazados")} {...a11yProps(tabValueType.rejected)} />
                    <Tab label={label("Viajes Completados")} {...a11yProps(tabValueType.completed)} />
                    </Tabs> 
                </Box>
                <TabPanel value={tabValue} index={tabValueType.suggested}>
                    <SuggestedTrips {...suggestedTripsProps}></SuggestedTrips>
                </TabPanel>
                <TabPanel value={tabValue} index={tabValueType.accepted}>
                    <Trips {...acceptedTripsProps}></Trips>
                </TabPanel>
                <TabPanel value={tabValue} index={tabValueType.rejected}>
                    <Trips {...rejectedTripsProps}> </Trips>
                </TabPanel>
                <TabPanel value={tabValue} index={tabValueType.completed}>
                    <Trips {...completedTripsProps}></Trips>
                </TabPanel>
            </Box>
        )
    }
    
    return(
        <>
            <Sidebar>
            <div className={styles.container}>
                <Indicators></Indicators>
                <hr className={styles.divider} id="divider" />
                {renderTabs()}
            </div>
            </Sidebar>
        </>
    )
}

export default MyTrips;