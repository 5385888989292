import { Form } from "./form.types";
import { DynamicDataContext } from "config/dynamicDataContext";
import { getCarriageTypeOptions, getRoutesOptions } from "components/common/Utils/apiOptionCalls";

export const generalTripForms: Form[]=[
  {
    formTitle:"1. ¿Dónde se recogerá tu carga?",
    fields:[
        {
            label:"Elige el Estado o la Ciudad de tu órigen",
            required:true,
            placeholder:"Ciudad",
            type:"dropdown",
            async:true,
            isMulti:false,
            asyncSearcher:"city",
            name:"origin",
        },
        {
            label:"Coloca la dirección completa de tu origen.",
            required:true,
            placeholder:"calle/número/colonia/ciudad/estado/C.P.",
            type:"text",
            name:"originAddress",
            disabled:false
        }
    ]
  },
  {
    formTitle:"2. ¿A dónde quieres enviar tu carga?",
    fields:[
        {
            label:"Elige el Estado o la Ciudad de tu destino.",
            required:true,
            placeholder:"Ciudad",
            type:"dropdown",
            async:true,
            isMulti:false,
            asyncSearcher:"city",
            name:"destiny",
        },
        {
            label:"Coloca la dirección completa de tu destino.",
            required:true,
            placeholder:"calle/número/colonia/ciudad/estado/C.P.",
            type:"text",
            name:"destinyAddress"
        }
    ]
  },
  {
    formTitle:"3. ¿Qué tipo de carga estás transportando?",
    fields:[
        {
            label:"Selecciona de las opciones a continuación.",
            required:true,
            placeholder:"Carga",
            type:"dropdown",
            disabled:false,
            optionsContext:DynamicDataContext,
            dynamicOptionsProp:"carriageType",
            options:[
                {
                    label:"Carga General...",
                    value:"1"
                },
                {
                    label:"Carga Alimenticia...",
                    value:"2"
                },
                {
                    label:"Carga Peligrosa...",
                    value:"3"
                },
            ],
            optionGetter:getCarriageTypeOptions,
            name:"carriageType"
        },
    ]
  },
  {
    formTitle:"4. Selecciona el cliente para quien se realiza este viaje.",
    fields:[
        {
            label:"Elige de las opciones que se muestran a continuación.",
            required:true,
            placeholder:"Carga",
            type:"dropdown",
            optionsContext:DynamicDataContext,
            dynamicOptionsProp:"clientRoutes",
            options:[
                {
                    label:"Loading ...",
                    value:"-1"
                },
            ],
            name:"clientRoute",
            optionGetter:getRoutesOptions
        },
    ]
  },
  {
    formTitle:"5. ¿Tienes algún comentario adicional o instrucción especial?",
    fields:[
        {
            label:"Coloca cualquier detalle, comentario ó instrucción especial de tu carga para el conocimiento del transportista.",
            required:false,
            placeholder:"Comentarios",
            type:"text",
            name:"SpecialComments"
        },
    ]
  },
  {
    formTitle:"6. Tarifa",
    fields:[
        {
            label:"Escribe aquí la tarifa que propones para este viaje.",
            required:true,
            placeholder:"0000",
            type:"number",
            options:[
                {
                    label:"Carga 1",
                    value:"1"
                },
                {
                    label:"Carga 2",
                    value:"2"
                }
            ],
            name:"rate"
        },
    ]
  }
]

export const completedTripForms: Form[]=[
    {
      formTitle:"Detalle de información enviada al transportista",
      fields:[
          {
              label:"ID OTM",
              required:false,
              disabled:true,
              placeholder:"d",
              type:"text",
              name:"IDOTM",
              value:"...."
          },
          {
            label:"Origen",
            required:false,
            disabled:true,
            placeholder:"",
            type:"text",
            name:"Origen",
            value:"...."
        },
        {
            label:"Destino ",
            required:false,
            disabled:true,
            placeholder:"",
            type:"text",
            name:"Destino ",
            value:"...."
        },
        {
            label:"Fecha de captura del viaje en Kango",
            required:false,
            disabled:true,
            placeholder:"",
            type:"text",
            name:"Fecha de captura del viaje en Kango",
            value:"...."
        },
        {
            label:"Tipo de Unidad",
            required:false,
            disabled:true,
            placeholder:"",
            type:"text",
            name:"Tipo de Unidad",
            value:"...."
        },
        {
            label:"Tipo de Carga",
            required:false,
            disabled:true,
            placeholder:"",
            type:"text",
            name:"Tipo de Carga",
            value:"...."
        },
        {
            label:"Tarifa DAL",
            required:false,
            disabled:true,
            placeholder:"",
            type:"text",
            name:"Tarifa DAL",
            value:"...."
        },
        {
            label:"Comentarios Adicionales",
            required:false,
            disabled:true,
            placeholder:"",
            type:"text",
            name:"Comentarios Adicionales",
            value:"...."
        }
      ]
    }
]

export const rejectedRateForm: Form[]=[
    {
      formTitle:"Tarifa",
      fields:[
          {
              label:"Escribe aquí la nueva tarifa propuesta para este viaje.",
              required:true,
              placeholder:"00.00mxn",
              type:"number",
              name:"rate"
          },
      ]
    }
  ]