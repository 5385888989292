import  React,{useState,useEffect,useContext} from 'react';
import styles from "./TripRow.module.scss";
import Sidebar from 'components/common/Sidebar';
import UserContext from "config/userContext";
import { Link, useNavigate } from "react-router-dom";
import { faCalendarDays, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { faTruck } from '@fortawesome/free-solid-svg-icons'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { faTruckArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { generalTrip } from 'types';
import Box from '@mui/material/Box';
import { transformDateDayMonthYear,capitalize } from 'components/common/Utils/utils';
import Disponibility from './Disponibility';
import UnitType from './UnitType';

export interface tripRowProps {
    type?:
    "regular"|
    "eraser" |
    "accepted"|
    "pending" |
    "suggested",
    trip:generalTrip
  }


const TripRow: React.FC<tripRowProps>=({
    type="regular",
    trip,
})=>{
    const unitTypeTitle = trip.unitType || "Not Found"
    const unitTypeDescription = trip.infoUnitTypeRel?.catUnitTypeDal?.Name || " "
    const strEta = trip.eta?transformDateDayMonthYear(trip.eta):"0000-00-00"
    const strLastResponse =  trip.lastResponse?transformDateDayMonthYear(trip.lastResponse):"0000-00-00"

    const renderUnit = (
        icon:IconDefinition,
        label:string,subHeader:string | null,
        description:React.ReactNode)=> {
        return (
            <div className={styles.unidad}>
                <div className={styles.header}>
                    <FontAwesomeIcon className={styles.icon} icon={icon} />
                    {label}
                </div>
                { subHeader && 
                <>
                    <div className={styles.subHeader}>
                    {subHeader}
                    </div>
                </>    
                }
                <div className={styles.description}>
                    {description}
                </div>
            </div>
        )
    }

    const renderDetails = (label:string,linkTo:string) =>{
        return(
            <div className={styles.detalles}>
                <Link style={{ textDecoration: 'none' }} to={linkTo}>
                <div className={styles.text}>
                    {label}
                </div>
                </Link>
            </div>        
        )
    }

    const renderOrigin = (origin:string,destiny:string)=>{
        return(
            <div className={styles.origen}>
            <div className={styles.from}>
                <div className={styles.iconContainer}>
                    <FontAwesomeIcon className={styles.icon} icon={faLocationDot} />
                </div>
                <div className={styles.textContainer}>
                    <div className={styles.title}>
                        Origen
                    </div>
                    <div className={styles.text}>
                        {origin}
                    </div>
                </div>
            </div>
            <div className={styles.arrive}>
                <div className={styles.iconContainer}>
                    <FontAwesomeIcon className={styles.icon} icon={faTruckArrowRight} />
                </div>
                <div className="textContainer">
                    <div className={styles.title}>
                        Destino
                    </div>
                    <div className={styles.text}>
                        {capitalize(destiny)}
                    </div>
                </div>
            </div>
        </div>
        )
    }

    const renderEta = ()=>{
        return renderUnit(faCalendarDays,"Fecha estimada de llegada",null,
        <>
            {strEta}
        </>)
    }

    const disponibility = ()=>{
        return <Disponibility hasReturn={trip.hasReturn}
        hasMultipleLocation={trip.hasMultipleLocation}
        lastResponse={trip.lastResponse}></Disponibility>
    }

    const renderUnitType = ()=>{
        return (
            <UnitType label="Tipo de Unidad" 
            description={unitTypeDescription}
            subHeader={unitTypeTitle}
            ></UnitType>
        )
    }

    const regularTripJsxArr:React.ReactNode[] = [
        renderOrigin(capitalize(trip.cityOrigin?trip.cityOrigin:"Not found"),capitalize(trip.cityDestiny?trip.cityDestiny:"Not found")),
        renderUnitType(),
        renderEta(),
        disponibility(),
        renderDetails("Ver detalles",`/trip/${trip.id}`)   
    ]

    const eraserTripJsxArr:React.ReactNode[] = [
        renderOrigin(capitalize(trip.cityOrigin?trip.cityOrigin:"Not found"),capitalize(trip.cityDestiny?trip.cityDestiny:"Not found")),
        renderUnit(faTruck,"Transportista",null,
            <>
                {trip.carrier}
            </>),
        renderEta(),
        renderUnit(faPenToSquare,"Última edición",null,
            <>
                {transformDateDayMonthYear(trip.CarriageRequest?.UpdatedAt)}
            </>),
        renderDetails("Continuar Editando",`/trip/${trip.id}`),   
    ]

    const pendingTripsJsxArr:React.ReactNode[] = [
        renderOrigin(capitalize(trip.cityOrigin?trip.cityOrigin:"Not found"),capitalize(trip.cityDestiny?trip.cityDestiny:"Not found")),
        renderUnit(faTruck,"Transportista",null,
            <>
                {trip.carrier}
            </>),
        renderEta(),
        renderUnit(faPenToSquare,"Capturado el",null,
            <>
                {transformDateDayMonthYear(trip.CarriageRequest?.UpdatedAt)}
            </>),  
        renderDetails("Ver detalles",`/trip/${trip.id}`)
    ]

    const acceptedTripsJsxArr:React.ReactNode[] = [
        renderOrigin(capitalize(trip.cityOrigin?trip.cityOrigin:"Not found"),capitalize(trip.cityDestiny?trip.cityDestiny:"Not found")),
        renderUnit(faTruck,"Transportista",null,
            <>
                {trip.carrier}
            </>),
        renderEta(),
        renderUnit(faCalendarDays,"Aceptado el",null,
            <>
                {strLastResponse}
            </>),
        renderDetails("Ver detalles",`/trip/${trip.id}`)
    ]

    const suggestedTripsJsxArr:React.ReactNode[] = [
        renderOrigin(capitalize(trip.cityOrigin?trip.cityOrigin:"Not found"),capitalize(trip.cityDestiny?trip.cityDestiny:"Not found")),
        renderUnitType(),
        renderEta(),
        disponibility(),
        renderDetails("Ver sugerencia",`/trip/${trip.id}/suggested`)
    ]

    const renderJSXArray=(jsxArray:React.ReactNode[])=>{
        let counter = 0
        return jsxArray.map(jsx=>{
            counter++;
            return(
                <div key={counter}>
                {jsx}
                </div>
            )
        })
    }

    const typeDictJsx = {
        "regular":regularTripJsxArr,
        "eraser":eraserTripJsxArr,
        "accepted":acceptedTripsJsxArr,
        "pending":pendingTripsJsxArr,
        "suggested":suggestedTripsJsxArr
    }

    return(
        <>
            <div className={`${styles.containerRow} ${styles[type]}`}>
                {renderJSXArray(typeDictJsx[type])}
            </div>
        </>
    )
}

export default TripRow;