import env from "react-dotenv";
import {fetchDataNoFilter, postRequest} from 'config/api/api';


export type validateVoucherRespose = {
  isValid:boolean
}

const apiValidateVooucher = async(idotm:string) =>{
    const url = `${env.REACT_APP_BACKEND}/validate-voucher/${idotm}`;
    const response = await fetchDataNoFilter(url)
    return response as validateVoucherRespose
}

export type saveCompleteCarriageData = {
  IdCarriage?: number,
  IdOTM?: string,
  CityOrigin?: number,
  AddressOrigin?: string,
  Rate?: number,
  CityDestiny?: number,
  AddressDestiny?: string,
  CarriageType?: number,
  Eta?: string,
  Weight?: number,
  LastCarrierResponse?: string,
  SpecialComments?: string,
  IdCarrier?: number,
  IdRoute?: number,
  CcpInfo?: Array<{
    IdProduct: number,
    ProductName: string,
    Mount: number,
    UnitValue: number,
    Weight: number
  }>
}


const apiSaveCompleteTrip = async(carriageRequest:saveCompleteCarriageData) =>{
  const url = `${env.REACT_APP_BACKEND}/save-complete-carriage`;
  const response = postRequest(url,carriageRequest)
  return response
}



export{
  apiValidateVooucher,
  apiSaveCompleteTrip
}

