import  React,{useState,useEffect,useContext} from 'react';
import styles from "./Pending.module.scss";
import { carriageDetail } from 'types';
import { Link, useNavigate } from "react-router-dom";
import {iTrip} from '../interface'
import { PropaneSharp } from '@mui/icons-material';
import KGOButton from 'components/common/KGOButton';
import PopUp from 'components/common/Popup';
import FormWithoutContext from 'components/Form/FormWithoutContext';
import { completedTripForms } from 'forms/FormsCatalogue';
import CCPForm from 'components/CCPForm';
import { FormProvider, useForm } from 'react-hook-form';
import HeaderCard from 'components/common/HeaderCard';

const PendingTrip: React.FC<iTrip>=({ trip})=>{
    const navigate = useNavigate();
    const clickTrips = ()=>{
        navigate(`/my-trips`);
    };

    const form = useForm()

    const [openPopup, setopenPopup] = useState(false);

    const formatCurrency = (amount: number | string | null | undefined): string => {
        if (amount == null) {
            return "";
        }
        const parsedAmount = typeof amount === 'string' ? parseFloat(amount) : amount;
        return parsedAmount.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }) + 'MXN';
    };

    const popupContent = (
		<div className={styles.popup}>
		  <div className={styles["popup-heading"]}>
            ¡Viaje completado exitosamente!
		  </div>
		  <div className={styles["popup-text"]}>
		  Enviamos al transportista la dirección completa y la 
          información de los productos a la carta porte. Si deseas 
          consultar los detalles de este viaje, encuéntralo en Viajes Completados.  
		  </div>
		  <div className={styles.buttons}>
		  <KGOButton size='small'
           color='tertiary'
           emphasis='high' action={() => clickTrips()}>
		 	Ir a mis viajes
		  </KGOButton>
		  </div>
		</div>
	);

    



    const renderFields = ()=>{
        const fields = [
            {label:"ID OTM",text:trip.CarriageRequest?.IdOTM || "..."},
            {label:"Origen",text:trip.CarriageRequest?.cityOriginRelation?.Name},
            {label:"Destino",text:trip.CarriageRequest?.cityDestinyRelation?.Name},
            {label:"Tipo de Unidad",text:trip.UnitType},
            {label:"Tipo de Carga",text:trip.CarriageRequest?.carriageTypeRelation?.Description},
            {label:"Tarifa",text:formatCurrency(trip.CarriageRequest?.Rate)},
            {label:"Comentarios Adicionales",text:trip.CarriageRequest?.SpecialComments}
        ]
        return(
            <div className={styles.groupField}>
                {fields.map(field =>{
                    return (
                        <div key={field.label} className={styles.fieldContainer}>
                            <div className={styles.label}>
                                {field.label}
                            </div>
                            <div className={styles.text}>
                                {field.text}
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    const renderHeaderCard = () =>{
        return(
            <HeaderCard title='¡Tu formulario ya fue enviado!'>
                El formulario que completaste para solicitar el viaje ya ha sido enviado al transportista. 
                Te notificaremos en cuanto obtengas respuesta.
            </HeaderCard>
        )
    }

    return(
        <>
            <FormProvider {...form}>
                {renderHeaderCard()}
                <div className={styles["form-container"]}>
                    {renderFields()}
                    <div className={styles.ccpLabel}>Productos que enviaste:</div>
                    <CCPForm disabled={true} cartaPorte={trip.CarriageRequest?.cartaPorte}></CCPForm>
                    <div className={styles.buttonContainer}>
                    <KGOButton action={()=>{return null}}
                        emphasis="high"
                        size="medium"
                        color="primary"
                        disabled={true}>En espera de respuesta</KGOButton>
                    </div>
                    <PopUp open={openPopup} onClose={() => {}} content={popupContent} />
                </div>
            </FormProvider>
        </>
    )
}

export {PendingTrip};