import { generalTrip, infoUnitTypeRel } from "types";
import {fetchData} from 'config/api/api';
import env from "react-dotenv";

export type suggestedTrips = {
    [key: string]: Array<{
      IdCarriage: number;
      IdCompany?: number;
      IdTrip?: number;
      IdLocation?: number;
      IdCityOrigin?: number;
      Cup?: string;
      Rate?: string;
      Weight?: string;
      Eta?: string;
      MultipleLocation?: boolean;
      CloseCircuit?: boolean;
      CreatedAt?: string;
      UpdatedAt?: string;
      LastResponse?: any;
      IdCityDestiny?: number;
      IdUnitType?: number;
      cup?: string;
      carriageBelongsToCompany?: {
        IdCompany?: number;
        CompanyName?: string;
        Apikey?: string;
        Disabled?: boolean;
        CreatedAt?: string;
        UpdatedAt?: string;
      };
      CityOrigin?: {
        IdCity?: number;
        Name?: string;
        State?: string;
        Country?: string;
        Disabled?: boolean;
        CreatedAt?: string;
        UpdatedAt?: string;
      };
      CityDestiny?: {
        IdCity?: number;
        Name?: string;
        State?: string;
        Country?: string;
        Disabled?: boolean;
        CreatedAt?: string;
        UpdatedAt?: string;
      };
      UnitType?: {
        IdUnitType?: number;
        Description?: string;
        Disabled?: boolean;
        CreatedAt?: string;
        UpdatedAt?: string;
      };
      infoUnitTypeRel?:infoUnitTypeRel;
      CupCarrier?: {
        IdCarrier?: number;
        Cup?: string;
        Name?: string;
        Disabled?: boolean;
        CreatedAt?: string;
        UpdatedAt?: string;
      };
      match?: Array<{
        IdCarriageMatches?: number;
        IdRoute?: number;
        IdCarriage?: number;
        CreatedAt?: string;
        route?: {
          IdRoute?: number;
          IdCompany?: number;
          IdUnitType?: number;
          IdCityOrigin?: number;
          IdCityDestiny?: number;
          Active?: boolean;
          Disabled?: boolean;
          CreatedAt?: string;
          UpdatedAt?: any;
          CityOrigin?: {
            IdCity?: number;
            Name?: string;
            State?: string;
            Country?: string;
            Disabled?: boolean;
            CreatedAt?: string;
            UpdatedAt?: string;
          };
          CityDestiny?: {
            IdCity?: number;
            Name?: string;
            State?: string;
            Country?: string;
            Disabled?: boolean;
            CreatedAt?: string;
            UpdatedAt?: string;
          };
        };
      }>;
    }>;
  };
  

const getSuggestedTrips = async()=>{
    const url = `${env.REACT_APP_BACKEND}/suggested-carriages`;
    const response = await fetchData(url) as suggestedTrips
    return response
}

export{
    getSuggestedTrips
}