import { useEffect, useState } from "react";
import { Form } from "../../forms/form.types";
import Field from "../../components/Form/Field";
import styles from "./RenderForm.module.scss";

interface RenderFormProps {
  inputForm: Form;
  style?:"MKT" | "KGO";
}
//{ inputForm ,style = "MKT"}
const RenderForm: React.FC<RenderFormProps> = (props) => {
  const [elements, setElements] = useState({} as Form);

  const formFields = props.inputForm.fields.map((field: any) => <Field style={props.style} {...field} key={field.name}></Field>)
    

  return <div className={styles["form-container"]}>{formFields}</div>;
};

export default RenderForm;
