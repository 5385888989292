import  React,{useState,useEffect,useContext, useRef} from 'react';
import styles from "./AcceptedTrip.module.scss";
import { carriageDetail } from 'types';
import { Link, useNavigate } from "react-router-dom";
import {iTrip} from '../interface'
import { PropaneSharp } from '@mui/icons-material';
import KGOButton from 'components/common/KGOButton';
import PopUp from 'components/common/Popup'
import { FormProvider, useForm } from 'react-hook-form';
import HeaderCard from 'components/common/HeaderCard';
import CCPForm from 'components/CCPForm';
import Banner from 'components/common/Banner';
import TextFieldValidator from 'components/Form/TextFieldValidator';
import { apiSaveCompleteTrip, apiValidateVooucher, saveCompleteCarriageData, validateVoucherRespose } from './AcceptedTrip.service';
import TextField from 'components/Form/TextField';
import { formatCurrency, isDateInTheFutureWithin24Hours, transformDate } from 'components/common/Utils/utils';

export interface AcceptedTripProps {
    trip: carriageDetail,
    _isDateInTheFutureWithin24Hours?: (date: string | undefined) => boolean,
    validateVoucher?: (idotm: string) => Promise<validateVoucherRespose>
} 


const AcceptedTrip: React.FC<AcceptedTripProps>=({ 
    trip,
    _isDateInTheFutureWithin24Hours = isDateInTheFutureWithin24Hours,
    validateVoucher = apiValidateVooucher
})=>{
    const navigate = useNavigate();
    const clickTrips = ()=>{
        navigate(`/my-trips`);
    };
    const form = useForm()
    const [openPopup, setopenPopup] = useState(false);

    const [formState,setFormState] = useState<"saving" | "saved" | "filling">("filling")

    
    const isIdOtmEditable = _isDateInTheFutureWithin24Hours(trip.Eta)
    const submitText = isIdOtmEditable ? "Completar Viaje" : "Viaje por Completar"
      
    const popupContent = (
		<div className={styles.popup}>
		  <div className={styles["popup-heading"]}>
            ¡Viaje completado exitosamente!
		  </div>
		  <div className={styles["popup-text"]}>
		  Enviamos al transportista la dirección completa y la 
          información de los productos a la carta porte. Si deseas 
          consultar los detalles de este viaje, encuéntralo en Viajes Completados.  
		  </div>
		  <div className={styles.buttons}>
		  <KGOButton size='small'
           color='tertiary'
           emphasis='high' action={() => clickTrips()}>
		 	Ir a mis viajes
		  </KGOButton>
		  </div>
		</div>
	);

    const renderHeaderCard = () =>{
        if(isIdOtmEditable){
            return(
                <HeaderCard dark={true} title='Espera un poco, ya casi terminas'>
                    Casi terminas exitosamente tu viaje dentro de Kango. 
                    Te invitamos a revisar los detalles e ir generando el viaje en el OTM. 
                    Una vez que esté habilitado, te notificaremos para que puedas añadir el ID y se concluya el viaje.
                </HeaderCard>
            )
        }
        else{
            return(
                <HeaderCard dark={true} title='¡Estás a un paso de completar!'>
                    Para finalizar el viaje dentro de Kango por favor coloca el ID del OTM y la dirección de envío completa. 
                    Así te ayudaremos a completar el proceso de la carta porte y le enviaremos la dirección al transportista.
                </HeaderCard>
            )
        }
    }

    const renderBanner = () =>{
        if(isIdOtmEditable == false){
            return(
                <Banner title="Importante"
                description='Te informamos que el transportista ya aceptó el viaje por medio del chatbot. Para continuar, puedes ir generando el viaje en OTM y escribir el ID en el siguiente campo una vez que esté habilitado. Este campo se habilitará 24 horas antes del cumplimiento del ETA de este viaje.'
                type="info"></Banner>
            )
        }else{
            return(
                <Banner 
                description='Recuerda que para completar el viaje debes crear el ID del mismo en OTM y escribirlo en Kango, así podremos ayudarte a llevar esta información al sistema de carta porte. '
                type="warning"></Banner>
            )
        }
    }

    const validator = async (text:string)=>{
        if(text == "" || !text) return Promise.resolve(false)
        return validateVoucher(text).then((response:validateVoucherRespose)=>{
            return response.isValid
        }).catch(error=>{
            alert("Error al validar el ID OTM")
            return false;
        })
    }

    const existsAndNotEmpty = (obj:object)=>{
        return obj && Object.keys(obj).length > 0;
    }

    const isFormValid = ()=>{
        return existsAndNotEmpty(form.formState.errors) ? false:true 
    }

    const generateAcceptedData = (data:carriageDetail,IdOtm:string):saveCompleteCarriageData=>{
        const result: saveCompleteCarriageData = {
            IdCarriage:data.IdCarriage,
            IdOTM:IdOtm,
            CityOrigin:data.CarriageRequest?.CityOrigin,
            AddressOrigin:data.CarriageRequest?.AddressOrigin || "",
            Rate:data.Rate? Number(data.Rate):undefined,
            CityDestiny:data.CarriageRequest?.CityDestiny,
            AddressDestiny:form.getValues().addressDestiny,
            CarriageType:data.CarriageRequest?.IdCarriageType,
            Eta:data.Eta ? transformDate(data.Eta) : undefined,
            Weight:data.CarriageRequest?.Weight ? Number(data.CarriageRequest?.Weight) : undefined,
            LastCarrierResponse:data.LastReponse ? transformDate(data.LastReponse) : undefined,
            SpecialComments:data.CarriageRequest?.SpecialComments,
            IdCarrier:data.CarriageRequest?.IdCarrier,
            IdRoute:data.CarriageRequest?.IdRoute,
            CcpInfo:data.CarriageRequest?.cartaPorte?.map((ccp: any) => ({
                IdProduct: ccp.IdProduct,
                ProductName: ccp.ProductName,
                Mount: Number(ccp.Mount),
                UnitValue: Number(ccp.UnitValue),
                Weight: Number(ccp.Weight)
            }))
        };
        return result;
    }

    const onSubmit = async (event:any)=>{
        event.preventDefault();
        await form.trigger()
        const isValid:boolean = isFormValid()
        if(isValid){
            const values = form.getValues()
            const data:saveCompleteCarriageData = generateAcceptedData(trip,values.IdOtm)
            setFormState("saving")
            apiSaveCompleteTrip(data).then(response=>{
                setopenPopup(true)
            }).catch(error=>{
                alert("Ha ocurrido un error al intentar completar el viaje.")
                setFormState("filling")
            })
        }
    }

    const renderOtmField = ()=>{
        return(
            <div className={styles.otmContainer}>
                <TextFieldValidator name='IdOtm' 
                value={trip.CarriageRequest?.IdOTM}
                placeholder={"Ingresa el ID OTM"}
                label={"ID OTM"}
                type="text"
                required={true}
                disabled={!isIdOtmEditable}
                validatorFunction={validator}
                validatorErrorMessage={"ID OTM invalido"}
                ></TextFieldValidator>
            </div>
        )
    }

    const renderDestinyAddress = ()=>{
        return(
            <div className={styles.destiny}>
                <TextField
                name='addressDestiny'
                value={trip.CarriageRequest?.AddressDestiny}
                placeholder={"Dirección de Destino"}
                label="Dirección de Destino"
                disabled={!isIdOtmEditable}
                required={true}
                type="text"
                style="KGO"></TextField>
            </div>
        )
    }

    const renderFields = ()=>{
        const fields = [
            {label:"Origen",text:trip.CarriageRequest?.cityOriginRelation?.Name},
            {label:"Destino",text:trip.CarriageRequest?.cityDestinyRelation?.Name},
            {label:"Tipo de Unidad",text:trip.UnitType},
            {label:"Tipo de Carga",text:trip.CarriageRequest?.carriageTypeRelation?.Description},
            {label:"Tarifa",text:formatCurrency(trip.CarriageRequest?.Rate)},
            {label:"Comentarios Adicionales",text:trip.CarriageRequest?.SpecialComments}
        ]
        return(
            <div className={styles.groupField}>
                {renderOtmField()}
                {renderDestinyAddress()}
                {fields.map(field =>{
                    return (
                        <div key={field.label} className={styles.fieldContainer}>
                            <div className={styles.label}>
                                {field.label}
                            </div>
                            <div className={styles.text}>
                                {field.text}
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    return(
        <>
            <FormProvider {...form}>
                <form onSubmit={onSubmit}>
                    <div className={styles.headerGroup}>
                        {renderHeaderCard()}
                        {renderBanner()}
                    </div>
                    <div className={styles["form-container"]}>
                        {renderFields()}
                        <div className={styles.ccpLabel}>Productos que enviaste:</div>
                        <CCPForm disabled={true} cartaPorte={trip.CarriageRequest?.cartaPorte}></CCPForm>
                        <div className={styles.buttonContiner}>
                            <KGOButton type="submit"
                            emphasis="high"
                            size="medium"
                            color="primary"
                            disabled={!isIdOtmEditable || formState=="saving"}
                            >{formState=="saving"?"Completando Viaje":submitText}</KGOButton>
                        </div>
                        <PopUp open={openPopup} onClose={() => {}} content={popupContent} />
                    </div>
                </form>
            </FormProvider>
        </>
    )
}

export {AcceptedTrip};