import  React,{useState,useEffect,useContext} from 'react';
import styles from "./Erasers.module.scss";
import Sidebar from 'components/common/Sidebar';
import UserContext from "config/userContext";
import { useNavigate } from "react-router-dom";
import Indicators from 'components/Indicators';
import TripRow from 'components/TripRow';
import { generalTrip } from 'types';
import { getDraftsTrips as getDraftsApi } from './Erasers.service';
import ImageHeader from 'components/common/ImageHeader';
import Banner from 'components/common/Banner';
import EmptyTrip from 'components/common/EmptyTrip';
import {useAxiosNavigation} from "config/api/AxiosInterceptor";

 export interface erasersProps{
    getDraftsTrips?:()=>Promise<generalTrip[]>,
}

const Erasers: React.FunctionComponent<erasersProps>=({
    getDraftsTrips = getDraftsApi
})=>{
    const navigate = useNavigate();
    const { userData, setUserData } = useContext(UserContext);
    const [trips, setTrips] = useState<generalTrip[]>([])
    const breadCrumbData = [
        {label:"Mis Viajes",linkTo:"/my-trips"},
        {label:"Borradores",linkTo:"#"},
    ]

    useAxiosNavigation();

    useEffect(() => {
        getDraftsTrips().then(trips=>{
            setTrips(trips)
        }) 
    }, [userData, navigate]);

    const renderTrips = ()=>{
        return trips.map(trip=>{
            return <TripRow  key={trip.id} type="eraser" trip={trip}></TripRow>
        })  
    }

    const renderBanner = ()=>{
        const tripsToExpire = countTripsAboutToExpire();
        if(tripsToExpire <=0) return;
        return(
            <div className={styles.bannerContainer}>
                <Banner 
            description={`Tienes ${tripsToExpire} viaje(s) que está por caducar, recuerda que los viajes caducados se eliminan automáticamente.`}
            type="warning"></Banner>
            </div>
        )
    }

    const countTripsAboutToExpire = ()=>{
        let counter = 0
        trips.map(trip=>{
            if(trip.eta){
                const eta = new Date(trip.eta)
                const days = daysDifference(eta,new Date())
                if(days <=2){
                    counter ++
                }
            }
        })
        return counter
    }

    const daysDifference = (d1:Date,d2:Date)=>{
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        if(d1.getTime() > d2.getTime()){
            return  Math.round(Math.abs((d1.getTime() - d2.getTime()) / oneDay));
        }else{
            return  -1 * Math.round(Math.abs((d1.getTime() - d2.getTime()) / oneDay))
        }
        
    }

    const noCompleted = ()=>{
        if(trips.length > 0) return
        return(
            <EmptyTrip header='¡Excelente! Por el momento no tienes ningún viaje en borrador.' sourceImg='/Assets/images/NoDrafts.svg'>
               Mientras tanto, puedes observar los viajes disponibles que tenemos en Viajes Generales
                ó los viajes que te sugerimos según tus rutas actuales en Viajes Sugeridos. 
            </EmptyTrip>
        )
    }

    return(
        <>
            <Sidebar>
            <ImageHeader title="Borradores" 
            breadCrumb={breadCrumbData}
            imgPath="Assets/images/TruckWithContainer.png" ></ImageHeader>
            <div className={styles.container}>
                <div className={styles.headerText}>Encuentra aquí los viajes que comenzaste a 
                    completar pero no terminaron de editarse.</div>
                {renderBanner()}
                {noCompleted()}
                {renderTrips()}
            </div>
            </Sidebar>
        </>
    )
}

export default Erasers;