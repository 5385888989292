import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FieldValues } from "react-hook-form";
import UserContext from "../../config/userContext";
import { loginForm } from "../../forms/Login";
import Section from "../../components/common/Section";
import View from "../../components/common/View";
import Form from "../../components/Form";
import BgSection from "../../components/common/BgSection";
import styles from "./Login.module.scss";
import { loginRequest as loginRequestApi } from "./Login.service";
import { User } from './Login.types'
import PopUp from 'components/common/Popup'
import StateIcon from "components/common/StateIcon";
import Button from "components/common/Button";
import useLocalStorage from "components/hooks/useLocalStorage";

type RedirectLocationState = {
  redirectTo: Location;
};

interface LoginProps {
  loginRequest?: (userName: string, userPassword: string) => Promise<any>;
}


const Login: React.FunctionComponent<LoginProps>= ({
  loginRequest = loginRequestApi,
}) => {
  
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const { userData, setUserData } = useContext(UserContext);
  const [openPopup, setopenPopup] = useState(false);
  const [PopupState, setPopupState] = useState<"success" | "error">("success");
  const { setValue } = useLocalStorage("userData", {});

  const loginRequestCall = (username:string,password:string)=>{
    loginRequest(username, password)
        .then((response:any) => {
          const user: User = {
            Username: response.data.Username,
            Token: response.data.Token,
            IsNew: response.data.IsNew
          };

          localStorage.setItem("token", response.data.Token);

          setUserData(user);
          setValue(user);

          if (user.IsNew) {
            navigate(`/onboarding`);
          } else {
            if(locationState){
              const { redirectTo } = locationState as RedirectLocationState;
              navigate(`${redirectTo.pathname}${redirectTo.search}`);
            }
            else{
              navigate(`/my-trips`);
            }
          }
        })
        .catch((error:any) => {
          setPopupState("error");
          console.log(error);
          setopenPopup(true);
        })
  }

  const onSubmit = (data: FieldValues) => {
    loginRequestCall(data["username"],data["password"])
  };

  const popupContent = (
    <div data-testid="popup" className={styles.popup}>
      <StateIcon state={PopupState} />
      <div className={styles["popup-text"]}>
        <strong>Contraseña incorrecta</strong>
        <br />
        Recuerde que su usuario es su número de nómina y su contraseña es su RFC.
      </div>
      <Button color="primary-filled" action={() => setopenPopup(false)}>
        Intentar de nuevo
      </Button>
    </div>
  );

  return (
    <View>
      <BgSection bgImage="/kango-logo-BGonDark.svg" 
          position="middle" 
          imageSize="large"
        />
      <div className={styles.container}>
        <div className={styles["form-container"]}>
          <Form
            inputForm={loginForm}
            onSubmit={onSubmit}
            submitTitle={"Ingresar"}
          />
        </div>
      </div>
      <PopUp open={openPopup} onClose={() => {}} content={popupContent} />
    </View>
  );
};

export default Login;
