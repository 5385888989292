import { Form } from "./form.types";

export const loginForm: Form = {
  formTitle: "¡Bienvenido a Kango!",
  formDescription: "",
  fields: [
    {
      name: "username",
      label: "Número de empleado",
      required: true,
      placeholder: "Número de empleado / Usuario",
      type: "text",
      value: "",
      tooltip: "Ingresa con tu número de empleado",
    },
    {
      name: "password",
      label: "RFC / Contraseña",
      required: true,
      placeholder: "RFC / Contraseña",
      type: "password",
      value: "",
      tooltip: "Utiliza tu RFC como contraseña",
    },
  ],
};
