import { fetchData } from "config/api/api";
import env from "react-dotenv";
import { carriageType, clientRoutes } from "types";
import { Option } from "forms/form.types";

const getCarriageTypesDetail = async()=>{
    const url = `${env.REACT_APP_BACKEND}/cat-carriage-types`;
    const response = await fetchData(url)
    return response as carriageType[]
}

const getClientRoutes = async()=>{
    const url = `${env.REACT_APP_BACKEND}/client-routes`;
    const response = await fetchData(url)
    return response as clientRoutes[]
}

const getCarriageTypeOptions = async ():Promise<Option[]>=>{
    let carriageTypeResponce = await getCarriageTypesDetail()

    let carriageTypeOptions:Option[] = carriageTypeResponce.map(carriage=>{
        return {
        label:carriage.Description,
        value:carriage.IdCarriageType.toString()
        }
    })
    
    return carriageTypeOptions
}

const getRoutesOptions = async():Promise<Option[]>=>{
    let clientRoutesResponse = await getClientRoutes()
    let clientRouteOptions:Option[] = clientRoutesResponse.map(route=>{
    return{label:route.Name,value:route.IdRoute.toString()}
    })
    return clientRouteOptions
}

export {getCarriageTypeOptions,getRoutesOptions}