import  React,{useState,useEffect,useContext} from 'react';
import styles from "./GeneralTrips.module.scss";
import Sidebar from 'components/common/Sidebar';
import UserContext from "config/userContext";
import { useNavigate } from "react-router-dom";
import TripRow from 'components/TripRow';
import { getGeneralTrips as getGeneralTripsApi } from 'views/MyTrips/MyTrips.service';
import { generalTrip } from 'types';
import ImageHeader from 'components/common/ImageHeader';
import SearchBar from "components/common/SearchBar";
import Banner from 'components/common/Banner';
import { normalizeString } from 'components/common/Utils/utils';
import {useAxiosNavigation} from "config/api/AxiosInterceptor";

export interface generalTripsProps{
    getGeneralTrips?:()=>Promise<generalTrip[]>,
}

const GeneralTrips: React.FunctionComponent<generalTripsProps>=({
    getGeneralTrips = getGeneralTripsApi
})=>{
    const navigate = useNavigate();
    const { userData, setUserData } = useContext(UserContext);
    const [trips, setTrips] = useState<generalTrip[]>([])
    const [allTrips,setAllTrips] = useState<generalTrip[]>([])

    useAxiosNavigation();

    useEffect(() => {
        getGeneralTrips().then(trips=>{
            trips = trips.filter(trip=>{
                if(!trip.id){
                    console.error("Este viaje no cuenta con idCarriage", trip)
                }
                return trip.cityDestiny != "Not found" && trip.cityOrigin != "Not found"
            })
            setTrips(trips)
            setAllTrips(trips)
        })
        
    }, [userData, navigate]);

    const renderTrips =  
        trips.map(trip=>{
        return <TripRow key={trip.id} trip={trip}></TripRow>
         })  

    

    const handleSearchText=(text:string)=>{

        if(text==''){
            setTrips(allTrips)
        }else{
            let tempTrips = allTrips.filter((e)=>{
                let destiny = e.cityDestiny ? e.cityDestiny : ''
                let origin = e.cityOrigin ? e.cityOrigin : ''
                let unityType = e.unitType ? e.unitType : ''
                return compareNormalizeStrings(text,[destiny,origin,unityType])
            })
            setTrips(tempTrips)  
        } 
    }

    const compareNormalizeStrings = (text:string,inputs:string[])=>{
        text = normalizeString(text)
        let res = false;
        inputs.forEach(input=>{
            input = normalizeString(input)
            if(input.includes(text)){
                res = true;
            }
        })
        return res
    }

    const renderSearchBar = ()=>{
        if(allTrips.length === 0) return
        return(
            <div className={styles["searchBarContainer"]}>
                <SearchBar placeholder='Buscar' handleTextUpdate={handleSearchText}></SearchBar>
            </div>
        )
    }

    const renderBanner = ()=>{
        return(
            <div className={styles.bannerContainer}>
                <Banner
                type='info'
                description='La información de esta sección contiene únicamente embarques de Deacero del día de hoy.'></Banner>
            </div>
        )
    }

    return(
        <>
            <Sidebar>
            <ImageHeader title="Viajes Generales" 
            imgPath="Assets/images/CargoContainer.png"></ImageHeader>
            <div className={styles.headerText}>
            Encuentra aquí todos los viajes de <strong>Deacero </strong> 
             que están disponibles actualmente.
            </div>
            <div className={styles.container}>
                {renderSearchBar()}
                {renderBanner()}
                {renderTrips}
            </div>
            </Sidebar>
        </>
    )
}

export default GeneralTrips;