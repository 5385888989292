//import styles from "Popup.module.scss";

import { Dialog, DialogContent } from "@mui/material";
import styles from "./Popup.module.scss";

interface PopupProps {
  open: boolean;
  onClose: () => void;
  closeButton?: boolean;
  content: React.ReactNode;
}

const Popup: React.FC<PopupProps> = ({
  open,
  onClose,
  closeButton = false,
  content,
}) => {
  return (
    <Dialog onClose={onClose} open={open}>
      {closeButton && (
        <a className={styles["close-button"]} onClick={onClose}>
          ×
        </a>
      )}
      <DialogContent>
        <div className={styles.container}>{content}</div>
      </DialogContent>
    </Dialog>
  );
};

export default Popup;
