import styles from "./SearchBar.module.scss";
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useState} from 'react'

interface SearchBarProps {
  placeholder?:string;
  handleTextUpdate:Function;
}

const SearchBar: React.FC<SearchBarProps> = ({
 placeholder = "Buscar",
 handleTextUpdate
}) => {
  const [input, setInput] = useState("")
  const handleChange = (e:any)=>{
    handleTextUpdate(e.target.value)
  }

  return (
  <>
      <div className={styles["container"]}>
        <div className={styles["searchBarContainer"]}>
          <input className={styles["searchInput"]} 
          placeholder={placeholder} 
          name="searchBar" 
          id="searchBar"
          onChange={e=>{handleChange(e)}}></input>
          <span className={styles["icons"]}>
            <FontAwesomeIcon onClick={()=>{return}} className={styles.icon} icon={faMagnifyingGlass} />
            {/* Icono de filtro <div className={styles["bar"]}></div>
            <FontAwesomeIcon onClick={()=>{return}} className={styles.icon} icon={faFilter} />  */}
          </span>
        </div>
      </div>
  </>
  );
};

export default SearchBar;
