import {postRequest} from 'config/api/api'
import env from "react-dotenv";

const loginRequest = async (userName: string, userPassword: string) => {
    const url = `${env.REACT_APP_BACKEND}/users/login`;
    const body = {
        Username: userName, 
        Password: userPassword
    }
    const response = await postRequest(url, body,true);
    return response;
};

export {
    loginRequest,
}
