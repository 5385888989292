import React, { useState } from "react";
import styles from "./CarrierInfoBar.module.scss";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faArrowsRotate,faRoad } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import KGOButton from "components/common/KGOButton";
import PopUp from "components/common/Popup";
import { carriageDetail } from "types";
import {
  capitalizeWords,
  extractEmails,
  getInitials,
  timeSince,
} from "components/common/Utils/utils";
import ContactForm from "./ContactForm";

export type CarrierInfoBarProps = {
  trip: carriageDetail;
};

const CarrierInfoBar: React.FunctionComponent<CarrierInfoBarProps> = (
  props
) => {
  let whatsappOptions: any = [];
  let mainCarrier: any = {};
  let mainCarrierIndex: number = -1;

  props.trip.InfoCarrier?.forEach((e, index) => {
    whatsappOptions.push({
      value: e.IdCarrierInfo,
      label: e.Name + " " + e.Phone,
    });
    if (e.Main) {
      mainCarrier = e;
      mainCarrierIndex = index;
    }
  });
  if (!mainCarrier.Name) {
    if (props.trip.InfoCarrier) {
      mainCarrier = props.trip.InfoCarrier[0]
        ? props.trip.InfoCarrier[0]
        : {
            Phone: "Not found",
            Email: "Not found",
            Name: "Not found",
          };
    }
  }

  const [openPopup, setopenPopup] = useState(false);
  let counter = 1;
  const emails = extractEmails(mainCarrier.Email).map((email) => {
    counter++;
    return (
      <div key={counter}>
        {email}
        <br />
      </div>
    );
  });

  const popupContent = (
    <div className={styles.popup}>
      <div className={styles["popup-heading"]}>
        ¡Tu solicitud ha sido enviada al transportista!
      </div>
      <div className={styles["popup-text"]}>
        Los datos se agregaron con éxito y consultamos disponibilidad con el
        transportista. Te notificaremos en cuanto recibamos respuesta.
      </div>
      <div className={styles.buttons}>
        <KGOButton
          size="small"
          color="tertiary"
          emphasis="high"
          action={() => setopenPopup(false)}
        >
          Entendido
        </KGOButton>
      </div>
    </div>
  );

  

  const renderLastResponse = () => {
    let iconColor = props.trip.LastReponse ? "blue300" : "warningColor";
    if (!props.trip.LastReponse) {
      return (
        <div className={styles.infoBlock}>
          <FontAwesomeIcon
            className={`${styles.lastResponseIcon} ${styles[iconColor]}`}
            icon={faUser}
          />
          <div className={styles.lastResponse}>
            <div className={`${styles.lastResponseTitle}`}>
              Transportista respondió por última vez:
            </div>
            <div className={styles.lastResponseLabel}>No ha respondido.</div>
          </div>
        </div>
      );
    }
    return (
      <div className={styles.infoBlock}>
        <FontAwesomeIcon
          className={`${styles.lastResponseIcon} ${styles[iconColor]}`}
          icon={faUser}
        />
        <div className={styles.lastResponse}>
          <div className={`${styles.lastResponseTitle}`}>
            Transportista respondió por última vez:
          </div>
          <div className={styles.lastResponseLabel}>
            {timeSince(props.trip.LastReponse)}
          </div>
        </div>
      </div>
    );
  };

  const renderZones = props.trip.zonesRelation?.map((zoneRelation) => {
    return (
      <div key={zoneRelation.IdZone} className={styles.zone}>
        {capitalizeWords(zoneRelation.catZone?.Description)}
      </div>
    );
  });

  const availabilityText = () => {
    const zonesLength = props.trip.zonesRelation
      ? props.trip.zonesRelation.length
      : 0;
       return (
        <>
          Disponible otro destino en:
          <div className={styles.zoneGroup}>{renderZones}</div>
        </>
      );
    /* if (
      (!props.trip.CloseCircuit && props.trip.MultipleLocation) ||
      zonesLength > 0
    ) {
      return (
        <>
          <div className={styles.availabilityTitle}>
            Disponible para cerrar circuito y otro destino en:
          </div>
          <div className={styles.zoneGroup}>{renderZones}</div>
        </>
      );
    }
    if (props.trip.MultipleLocation || zonesLength > 0) {
      return (
        <>
          Disponible otro destino en:
          <div className={styles.zoneGroup}>{renderZones}</div>
        </>
      );
    }
    if (!props.trip.CloseCircuit) {
      return (
        <div className={styles.availabilityTitle}>
          Disponible para cerrar circuito
        </div>
      );
    }
    if (props.trip.CloseCircuit && !props.trip.MultipleLocation) {
      return <div className={styles.availabilityTitle}>No disponible</div>;
    } */
  };

  const renderAvailability = () => {
    //if (!props.trip.LastReponse) return <></>;
    return (
      <div className={styles.infoBlock}>
        <FontAwesomeIcon
          className={styles.availabilityIcon}
          icon={faArrowsRotate}
        />
        <div className={styles.availability}>{availabilityText()}</div>
      </div>
    );
  };

  const renderUnitType = ()=>{
    return(
      <div className={styles.infoUnitBlock}>
        <div className={styles.unitType}>
          <div className={styles.unitTypeTitle}>Tipo de Unidad:</div>
          <div className={styles.unitTypeLabel}>
            {props.trip.UnitType}
            <br></br>
            {props.trip.UnitTypeDal &&<>
              <strong>({props.trip.UnitTypeDal})</strong>
            </>}   
          </div>
        </div>
      </div>
    )
  }

  const renderLicensePlates = ()=>{
    if(!props.trip.LicensePlate) return;
    return(
      <div className={styles.infoUnitBlock}>
        <div className={styles.unitType}>
          <div className={styles.unitTypeTitle}>Placas de la Unidad:</div>
          <div className={styles.unitTypeLabel}>{props.trip.LicensePlate}</div>
        </div>
      </div>
    )
  }

  const renderUnitInfo = ()=>{
    return(
      <>
      <div className={styles.divBar}></div>
        <div className={styles.unitInfo}>
          <div className={styles.unitInfoTitle}>Información de la Unidad:</div>
          <div className={styles.unitInfoContainer}>
            {renderUnitType()}
            {renderLicensePlates()}
          </div>
        </div>
      </>
    )
  }

  

  return (
    <>
      <div className={styles.carrierContainer}>
        <>
          <div className={styles.title}>Información del transportista:</div>
          <div className={styles.carrierDetailContainer}>
            <div className={styles.initials}>
              <div className={styles.initialsText}>
                {getInitials(mainCarrier.Name)}
              </div>
            </div>
            <div className={styles.carrierDetails}>
              <div className={styles.detail}>
                <div className={styles.detailHeader}>Transportista:</div>
                <div className={styles.detailLabel}>{mainCarrier.Name}</div>
              </div>
              <div className={styles.detail}>
                <div className={styles.detailHeader}>Email</div>
                <div className={styles.detailLabel}>
                  {mainCarrier.Email ? emails : " "}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.divBar}></div>
          <ContactForm
            whatsappOptions={whatsappOptions}
            mainCarrier={mainCarrier}
            mainCarrierIndex={mainCarrierIndex}
            idCarrier={props.trip.CupCarriage?.IdCarrier}
            idCarriage={props.trip.IdCarriage}
            lastResponse={props.trip.LastReponse}
            onOpen={() => setopenPopup(true)}
          ></ContactForm>
          {renderAvailability()}
          {renderLastResponse()}
          {renderUnitInfo()}
        </>
      </div>
      <PopUp
        open={openPopup}
        onClose={() => {
          setopenPopup(false);
        }}
        closeButton={true}
        content={popupContent}
      />
    </>
  );
};

export default CarrierInfoBar;
