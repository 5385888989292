export type infoUnitTypeRel = {
  IdUnitTypeRel?: number,
  IdUnitType?: number,
  IdUnitTypeDAL?: number,
  catUnitTypeDal?: {
  IdUnitType?: number,
  Name?: string,
  CreatedAt?: string
  }
}

export type generalTrip = {
    cityOrigin?:string,
    cityDestiny?:string,
    unitType?:string,
    eta?:string,
    hasMultipleLocation?:boolean,
    hasReturn?:boolean,
    cityOriginState?:string,
    cityDestinyState?:string,
    id:number,
    carrier?:string,
    lastResponse?:string |null,
    infoCarrier?:infoCarrier[],
    updatedAt?:string,
    CarriageRequest?:CarriageRequest,
    infoUnitTypeRel?:infoUnitTypeRel
}

export type infoCarrier = {
    CreatedAt?: string;
    Disabled?: boolean;
    Email?: string;
    IdCarrier: number;
    IdCarrierInfo: number;
    Main?: boolean;
    Name?: string;
    Phone?: string;
    UpdatedAt?: string;
}

type CarriageResponseRelation = {
  IdCarrierResponse: number;
  IdResponse?: number;
  IdRequest?: number;
  Message?: string;
  Schedule?: string;
  LicensePlate?: string | null;
  OperatorName?: string | null;
  FumigationCert?: boolean;
  ImssAffiliate?: boolean;
  SpecialConditions?: string;
  AnythingElse?: string;
  Score?: number;
  CreatedAt?: string;
  UpdatedAt?: string;
};

type LocationRelation = {
  IdLocation?: number;
  Description?: string;
  CreatedAt?: string;
  UpdatedAt?: string;
};



export type CarriageRequest = {
  IdRequest?: number;
  IdCarriage?: number;
  IdCarrier?: number;
  IdCarriageType?: number;
  IdUser?: number;
  IdLot?: number;
  Weight?: string;
  Rate?: string;
  SpecialComments?: string;
  IdOTM?: string;
  CityOrigin?: number;
  AddressOrigin?: string;
  CityDestiny?: number;
  AddressDestiny?: string;
  IdRoute?: number;
  IdCsc?: any;
  CreatedAt?: string;
  UpdatedAt?: any;
  idCarriage?: number;
  cityOriginRelation?: CityRelation;
  cityDestinyRelation?: CityRelation;
  cartaPorte?: CartaPorte[];
  carriageTypeRelation?:{
    IdCarriageType?:number,
    Description?:string,
    Disabled?:boolean,
    CreatedAt?:string,
    UpdatedAt?:string
  }
  carriageResponseRelation?:CarriageResponseRelation;
}

type ZonesRelation = {
  IdCarriageZone?: number;
  IdCarriage?: number;
  Disabled?: boolean;
  CreatedAt?: string;
  UpdatedAt?: string | null;
  IdZone?: number;
  catZone?: {
    IdZone?: number;
    Description?: string;
    Disabled?: boolean;
    CreatedAt?: string;
    UpdatedAt?: string;
  };
};


type CityRelation = {
  IdCity?: number;
  Name?: string;
  State?: string;
  Country?: string;
  Disabled?: boolean;
  CreatedAt?: string;
  UpdatedAt?: string;
}

export type CartaPorte = {
  IdCarriageCcp?: number;
  IdRequest?: number;
  IdProduct?: number;
  ProductName?: string;
  Mount?: string;
  UnitValue?: string;
  Weight?: string;
  CreatedAt?: string;
}


export type carriageDetail = {
    IdCarriage: number;
    CityOrigin?: string;
    CityDestiny?: string;
    Rate?: string;
    UnitType?: string;
    Eta?: string;
    Status?: string;
    CloseCircuit?: boolean;
    MultipleLocation?: boolean;
    Schedule?: string;
    Cup?: string;
    LastReponse?: string | null;
    IdTrip?:string|number;
    LicensePlate?:string;
    locationRelation?:LocationRelation;
    zonesRelation?:ZonesRelation[];
    UnitTypeDal?:string
    InfoCarrier?: Array<{
      IdCarrierInfo?: number;
      IdCarrier?: number;
      Name?: string;
      Phone?: string;
      Email?: string;
      Main?: boolean;
      Disabled?: boolean;
      CreatedAt?: string;
      UpdatedAt?: string;
    }>;
    CarriageRequest?:CarriageRequest;
    CupCarriage?:{
      CreatedAt:string;
      Cup:string;
      Disabled:boolean;
      IdCarrier:number;
      Name:string;
      UpdatedAt:string;
    }
  };

  export type carriageType = {
    IdCarriageType: number,
    Description: string,
    Disabled?: boolean,
    CreatedAt?: string,
    UpdatedAt?: string
  }

  export type clientRoutes = {
    Name: string;
    IdClientCompany: number;
    IdRoute: number;
  };

  export type product = {
    IdProduct:number,
    ProductName?: string,
    Mount?:string,
    Weight?:string,
    UnitValue?:string
  }

  export enum reponseId {
    Accepted = 1,
    Rejected = 2,
    RejectedByRate = 3
  }