import  React,{useState,useEffect,useContext} from 'react';
import styles from "./Trips.module.scss";
import { generalTrip } from 'types';
import { Link, useNavigate } from "react-router-dom";
import TripRow from 'components/TripRow';
import SearchBar from "components/common/SearchBar";
import EmptyTrip from 'components/common/EmptyTrip';
import { normalizeString } from 'components/common/Utils/utils';
import {useAxiosNavigation} from "config/api/AxiosInterceptor";

export interface TripsProps {
    headerText:string,
    emptyTripHeader:string,
    emptyTripSourceImg:string,
    emptyTripChildren:React.ReactNode,
    tripSearcher: ()=>Promise<generalTrip[]>,
}

const Trips: React.FC<TripsProps>=({
    headerText,
    emptyTripHeader,
    emptyTripSourceImg,
    tripSearcher,
    emptyTripChildren,
})=>{
    const [trips, setTrips] = useState<generalTrip[]>([])
    const [allTrips,setAllTrips] = useState<generalTrip[]>([])

    const noSuggested = ()=>{
        if(allTrips.length > 0) return
        return(
            <EmptyTrip header={emptyTripHeader} sourceImg={emptyTripSourceImg}>
                {emptyTripChildren}
            </EmptyTrip>
        )
    }

    const handleSearchText=(text:string)=>{

        if(text==''){
            setTrips(allTrips)
        }else{
            let tempTrips = allTrips.filter((e)=>{
                let destiny = e.cityDestiny ? e.cityDestiny : ''
                let origin = e.cityOrigin ? e.cityOrigin : ''
                let unityType = e.unitType ? e.unitType : ''
                return compareNormalizeStrings(text,[destiny,origin,unityType])
            })
            setTrips(tempTrips)  
        } 
    }

    const compareNormalizeStrings = (text:string,inputs:string[])=>{
        text = normalizeString(text)
        let res = false;
        inputs.forEach(input=>{
            input = normalizeString(input)
            if(input.includes(text)){
                res = true;
            }
        })
        return res
    }

    const renderSearchBar = ()=>{
        if(allTrips.length === 0) return
        return(
            <div className={styles["searchBarContainer"]}>
                <SearchBar handleTextUpdate={handleSearchText}></SearchBar>
            </div>
        )
    }

    useAxiosNavigation();

    useEffect(() => {
        tripSearcher().then(trips=>{
            setTrips(trips)
            setAllTrips(trips)
        })   
    },[]);

    const renderTrips =  
        trips.map(trip=>{
        { 
            return <TripRow key={trip.id} type="regular" trip={trip}></TripRow>
        }
         })  

    return(
        <>
            <div className={styles.containerRow}>
                <div className={styles.headerText}>{headerText}</div>
                {noSuggested()}
                {renderSearchBar()}
                {renderTrips}
            </div>
        </>
    )
}

export default Trips;