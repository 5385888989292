import  React,{useState,useEffect,useContext} from 'react';
import styles from "./UnitType.module.scss";
import Sidebar from 'components/common/Sidebar';
import UserContext from "config/userContext";
import { Link, useNavigate } from "react-router-dom";
import { faCalendarDays, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { faTruck } from '@fortawesome/free-solid-svg-icons'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { faTruckArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { generalTrip } from 'types';
import Box from '@mui/material/Box';
import { transformDateDayMonthYear,capitalize } from 'components/common/Utils/utils';

export interface unitTypeProps {
    label:string,
    subHeader?:string,
    description?:string,
}


const UnitType:React.FC<unitTypeProps>=({
    label,
    subHeader,
    description
})=>{

    return (
        <div className={styles.unidad}>
            <div className={styles.header}>
                <FontAwesomeIcon className={styles.icon} icon={faTruck} />
                {label}
            </div>
            { subHeader && 
            <>
                <div className={styles.subHeader}>
                {subHeader}
                </div>
            </>    
            }
             <div className={styles.description}>
                {description}
            </div>
        </div>
    )
}

export default UnitType;