//import styles from "Popup.module.scss";


import styles from "./KGOButton.module.scss";

interface KGOButtonProps {
    children?: React.ReactNode;
    action?: (data?: any) => void;
    size:
    | "large"
    | "medium"
    | "small";
    color:
    | "primary"
    | "secondary"
    | "tertiary";
    emphasis:
    | "high"
    | "medium"
    | "low";
    disabled?:boolean;
    dark?:boolean;
    type?:
    | "submit"
    | "reset"
    | "button";

}

const KGOButton: React.FC<KGOButtonProps> = (
  {
    children,
    action = ()=>{return null},
    size,
    color,
    emphasis,
    disabled = false,
    dark = false,
    type = "button"
  }
) => {
  
  return (
    <button
    disabled={disabled}
    type={type}
    className={`${styles[size]} ${styles[color+"-"+emphasis]} ${dark ? styles["dark"] : "" } `}
    onClick={type == "submit" ? undefined : action}>
        {children}
    </button>
  );
};



export default KGOButton;
