import styles from "./Section.module.scss";

interface SectionProps {
  title?: string | React.ReactNode;
  titleSize?: "small" | "medium" | "large";
  children?: React.ReactNode;
  variant?: "margin-title" | "no-margin-title";
  widthSize?: "medium" | "large" | "full";
  action?: React.ReactNode;
  style?:"MKT" | "KGO";
}

const Section: React.FC<SectionProps> = ({
  title,
  titleSize = "medium",
  children,
  variant = "margin-title",
  widthSize = "large",
  action,
  style = "MKT"
}) => {
  const actionClass = action ? "action" : "";
  if(style==="KGO"){
    return(
      <div className={styles.KGOContainer}>
        <div className={styles.KGOTitle}>
        {title}
        </div>
        <div> {children} </div>
      </div>  
    )
  }
  return (
    <div
      className={`${styles[`${widthSize}-container-width`]} ${
        styles.container
      }`}
    >
      {title && (
        <div
          className={`${styles[`${titleSize}-title`]} ${styles[variant]} ${
            styles[actionClass]
          }`}
        >
          {title}
          {action}
        </div>
      )}
      <div className={styles.children}> {children} </div>
    </div>
  );
};

export default Section;
