import { useEffect, useState, useContext} from "react";
import { Form } from "../../forms/form.types";
import Field from "../Form/Field";
import styles from "./OnBoardingUser.module.scss";
import Select from "../Form/Select"
import WestIcon from '@mui/icons-material/West';
import TextField from '../Form/TextField'
import EmailField from '../Form/EmailField'
import Button from '../common/Button'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {getUnitTypes} from './OnBoardingUser.service'
import UserContext from "config/userContext";
import KGOButton from "components/common/KGOButton";

interface OnBoarding3Props {
  next: Function;
  back:Function;
  submit?:Function;
}

const OnBoarding3: React.FC<OnBoarding3Props> = ({ 
   next,
   back,
   submit=()=>{return}
 }) => {
  const { userData, setUserData } = useContext(UserContext);

  useEffect(()=>{
  },[])

return(
  <>
  <div className={styles.container}>
    <div className={styles.middle}>
      <div>
          <div className={styles.head}>
          ¡Hola {userData.Username}!
          </div>
          <div className={styles.label}>
            ¡Kango está aquí para conectarte! Te notificaremos para que puedas cerrar circuitos o encontrar el mejor transportista y unidad en tu ubicación deseada. 
          </div>
          <div className={styles.label}>
            Ingresa tus datos y recuerda que puedes modificar tus datos más adelante, por lo pronto Kango te estará avisando por correo los viajes que encuentre para ti. 
          </div>
          <TextField
          label={'Whatsapp'}
          required={true}
          placeholder={'Ingresa tu número de whatsApp'}
          type={'text'}
          name={'whatsapp'}
          errorMessage={'Se requiere número de 10 dígitos'}
          ></TextField>
          <EmailField
          label={'Email'}
          required={true}
          placeholder={'Ingresa tu número de Email'}
          type={'name'}
          name={'email'}
          errorMessage={'Email inválido'}></EmailField>
      </div>
      <div className={styles.btnBar}>  
          <KGOButton 
          action={()=>{submit()}} 
          color={'primary'}
          emphasis={'high'}
          size="medium">Guardar y continuar
          </KGOButton>
      </div>
    </div>
    <div className={styles.right}></div>
  </div>
  </>
)
};

export default OnBoarding3;
