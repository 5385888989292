import {postRequest} from 'config/api/api'
import env from "react-dotenv";

const infoUserRequest = async (email: string, whatsapp: string) => {
    const url = `${env.REACT_APP_BACKEND}/info-users`;
    const body = {
        whatsapp: whatsapp,
        email: email, 
    }
    const response = await postRequest(url, body,true);
    return response;
};

export {
    infoUserRequest,
}