import  React,{useState,useEffect,useContext} from 'react';
import { Link } from 'react-router-dom';
import BreadCrumb from '../BreadCrumb';
import styles from "./ImageHeader.module.scss";

export interface ImageHeaderProps {
    imgPath:string,
    title:string,
    breadCrumb?:Array<{
        label:string,
        linkTo:string
    }>
}

     const ImageHeader: React.FC<ImageHeaderProps> = (
            {
                imgPath,
                title,
                breadCrumb
            }
         ) =>{


        const renderBreadCrumb = ()=>{
            if(breadCrumb)
            return(
                <BreadCrumb breadCrumb={breadCrumb}></BreadCrumb>
            )
        }

        return(
            <div className={styles.container}>
                <div className={styles.bg}>
                    <img src={imgPath} alt="Camión con contenedor"/>
                    <div className={styles.title}>
                        {title}
                    </div>
                </div>
                <div className={styles.breadCrumbContainer}>
                    {renderBreadCrumb()}
                </div>
            </div>
        )
    }

export default ImageHeader;